import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { CircleTooltip } from '../../../../components';
import styles from '../../style/ImpactReportPage.module.scss';
import s from './style/EconomicContributionBlock.module.scss';
import abbreviateNumber from '../../../../utils/functions/abbreviateNumber';

interface EconomicContributionBlockType {
  totalJobs: number;
  directJobs: number;
  totalContribution: number;
  isLoading: IsLoadingEconomicType;
}

type IsLoadingEconomicType = {
  totalJobs: boolean;
  directJobs: boolean;
  totalContribution: boolean;
};

const EconomicContributionBlock: FC<EconomicContributionBlockType> = ({
  totalJobs,
  directJobs,
  totalContribution,
  isLoading,
}) => (
  <div className={`row ${s.widget}`}>
    <div className="col-sm-6" style={{ paddingRight: '10px' }}>
      <div
        className={`${s.widget__wrapper}`}
        style={{
          padding: '20px 40px',
        }}
      >
        <>
          <h4 className={`${s.widget__wrapper__title}`}>
            Jobs Sustained & Created
            <span className={`${styles.chartSubTitle__margin}`}>
              <CircleTooltip
                description={
                  <div className={`${styles.tooltip__text}`}>
                    The sum of (1) credit union employees (2) the jobs at credit
                    union suppliers, supported by credit union expenses, (3)
                    jobs at businesses patroned by credit union consumers,
                    supported by dividends paid to consumers, and (4) jobs at
                    businesses benefiting from credit union lending, each at the
                    end of the last available calendar year.
                  </div>
                }
              />
            </span>
          </h4>
          <div className={`${s.widget__wrapper__content} row`}>
            {isLoading.totalJobs ? (
              <div className="col-6">
                <div
                  className={s.widget__wrapper__content__value}
                  style={{ marginBottom: '4px' }}
                >
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${styles.loading__sm} ${s.widget__wrapper__content__label}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
            ) : (
              <div className={`${s.loadingLabel} col-6`}>
                <div className={s.widget__wrapper__content__value}>
                  {abbreviateNumber(totalJobs)}
                </div>
                <div className={s.widget__wrapper__content__label}>
                  Total Jobs
                </div>
              </div>
            )}
            {isLoading.directJobs ? (
              <div className="col-6">
                <div
                  className={s.widget__wrapper__content__value}
                  style={{ marginBottom: '4px' }}
                >
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${styles.loading__sm} ${s.widget__wrapper__content__label}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
            ) : (
              <div className={`${s.loadingLabel} col-6`}>
                <div className={s.widget__wrapper__content__value}>
                  {abbreviateNumber(directJobs)}
                </div>
                <div className={s.widget__wrapper__content__label}>
                  Direct Jobs
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
    <div className="col-sm-6" style={{ paddingLeft: '10px' }}>
      <div
        className={`${s.widget__wrapper}`}
        style={{
          padding: '20px 40px',
        }}
      >
        <>
          <h4 className={`${s.widget__wrapper__title}`}>
            Economic Contribution
            <span className={`${styles.chartSubTitle__margin}`}>
              <CircleTooltip
                description={
                  <div className={`${styles.tooltip__text}`}>
                    The sum of (1) compensation paid to credit union employees,
                    (2) revenues paid to credit union suppliers, (3) revenues
                    received by businesses patroned by credit union consumers,
                    supported by dividends paid to consumers, and (4) increases
                    in credit unions’ net worth, each during the last available
                    calendar year. Note that actual economic contributions are
                    larger than our estimates, since here we do not include, for
                    instance, benefits to consumers and communities through
                    credit union lending, volunteering, and community outreach.
                  </div>
                }
              />
            </span>
          </h4>
          <div className={`${s.widget__wrapper__content} row`}>
            {isLoading.totalContribution ? (
              <div className={`${s.loadingLabel} col-6`}>
                <div
                  className={s.widget__wrapper__content__value}
                  style={{ marginBottom: '4px' }}
                >
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${styles.loading__sm} ${s.widget__wrapper__content__label}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
            ) : (
              <div className="col-12">
                <div className={s.widget__wrapper__content__value}>
                  {`$${abbreviateNumber(totalContribution)}`}
                </div>
                <div className={s.widget__wrapper__content__label}>
                  Total Contribution
                </div>
              </div>
            )}
          </div>
        </>
      </div>
    </div>
  </div>
);
export default EconomicContributionBlock;
