/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { makeStyles } from '@material-ui/core/styles';

export type CancelModelType = {
  isOpen: boolean;
  title?: string;
  applyBtnText?: string;
  cancelBtnText?: string;
  description?: string;
  stopEdit: Function;
  continueEdit: Function;
};

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    width: '471px',
    height: '246px',
    top: '15%',
    zIndex: 3000,
  },
  componentStyle: {
    border: 'solid 2px #0070D8',
  },
  heading: {
    padding: '32px',
    border: 'none',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
  },
  cancelButton: {
    height: '40px',
    width: '132px',
    '&:hover': {
      boxShadow: '0px 2px 15px rgb(0 0 0 / 15%)!important',
      borderRadius: '4px!important',
      background: 'white!important',
      color: '#282828!important',
    },
  },
  submitButton: {
    height: '40px',
    width: '182px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#5CB082!important',
    },
  },
  content: {
    width: '98%',
    border: 'none',
    padding: '0 32px',
    height: '100%',
    fontFamily: 'Open Sans',
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '22px',
    alignItems: 'center',
    color: '#282828',
  },
  action: {
    padding: '32px!important',
    width: '100%',
    display: 'flex',
  },
  button: {
    height: '40px',
    '&:hover': {
      backgroundColor: '#22447D!important',
    },
  },
});
const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children?: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const CancelModel: FC<CancelModelType> = ({
  isOpen,
  stopEdit,
  continueEdit,
  applyBtnText = 'Save changes',
  cancelBtnText = 'Don’t save',
  description = 'You have unsaved changes which will be lost unless you hit save. Do you want to save?',
  title = ' You have not saved all your previous work',
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.dialog,
      }}
      style={{ zIndex: 3000 }}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className={classes.heading}>
        <span style={{ fontWeight: 700 }}>{title}</span>
      </DialogTitle>
      <DialogContent className={classes.content}>{description}</DialogContent>
      <DialogActions className={classes.action}>
        <Button
          onClick={() => stopEdit()}
          size="large"
          variant="contained"
          color="secondary"
          className={classes.cancelButton}
          style={{
            border: '2px solid #EFF2F7',
            fontSize: '20px',
            width: cancelBtnText !== 'Discard and Exit' ? '132px' : '182px',
          }}
        >
          <span style={{ fontSize: '14px', color: '#2B39B9' }}>
            {cancelBtnText}
          </span>
        </Button>
        <Button
          onClick={() => continueEdit()}
          size="large"
          className={classes.submitButton}
          variant="contained"
          style={{ background: '#52B36B' }}
        >
          <span style={{ fontSize: '14px' }}>{applyBtnText}</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelModel;
