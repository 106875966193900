import React, { FC } from 'react';
import s from './style/ButtonLink.module.scss';
import { CircleTooltip } from '../CircleTooltip';

type ButtonLinkProps = {
  tooltipText: string;
  buttonText: string;
  href: string;
};

const ButtonLink: FC<ButtonLinkProps> = ({ buttonText, href, tooltipText }) => (
  // eslint-disable-next-line react/jsx-no-target-blank
  <a className={s.button} href={href} target="_blank">
    <span className={s.button__text}>{buttonText}</span>
    <span>
      <CircleTooltip
        description={tooltipText}
        fullWidth
        width="220px"
        iconStyle={{ width: '14px', height: '14px' }}
      />
    </span>
  </a>
);

export default ButtonLink;
