/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, memo, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { SelectComponent } from './Select';
import { CircleTooltip, Legend } from '../../../../components';
import s from './style/RaceChart.module.scss';
import LoadingChartWidget from './LoadingChartWidget';

const methadologyDescription = {
  raceBIFSG:
    'The match rate for the BIFSG model represents the number of consumers we were able to estimate race/ethnicity for by using their first name, last name, and location. Unmatched consumers have uncommon first and/or last names. Match rates above 10% produce estimates that are considered reliable. ',
  raceBISG:
    'The match rate for the BISG model represents how many consumers we were able to estimate race/ethnicity for by using their last name and location. Unmatched consumers have uncommon last names. Match rates above 10% produce estimates that are considered reliable.',
};

type RaceType = {
  white: number;
  black: number;
  native: number;
  asian: number;
  islander: number;
  other: number;
  twoPlus: number;
  hispanic: number;
  matchPercent: number | null;
};

type Chart = {
  raceWA: null | RaceType;
  raceBISG: null | RaceType;
  raceBIFSG: null | RaceType;
};
type RaceChartType = {
  cuName: string;
  comparisonName: string;
  isLoading: boolean;
  isDataExist: boolean;
  chartData: Chart;
  comparisonChartData: Chart;
  isComparison: boolean;
  setMethodology?: Function;
};
const RaceChart: FC<RaceChartType> = ({
  cuName,
  comparisonName,
  isLoading,
  chartData,
  comparisonChartData,
  isDataExist,
  setMethodology,
  isComparison,
}) => {
  const [selected, setSelected] = useState<string>('raceBIFSG');
  const handleSelected = (selectedValue: string) => {
    if (selectedValue !== selected) {
      setMethodology && setMethodology(selectedValue);
      setSelected(selectedValue);
    }
  };

  const colorPalette = isDataExist
    ? ['#232B95', '#52B36B']
    : ['#52B36B', '#52B36B'];

  if (isLoading) {
    return <LoadingChartWidget title="Race & Hispanic" />;
  }

  const arePropertiesZero = (obj: RaceType): boolean => {
    const propertiesToCheck = [
      'white',
      'black',
      'native',
      'asian',
      'islander',
      'other',
      'twoPlus',
      'hispanic',
    ];
    return propertiesToCheck.every((prop) => obj[prop as keyof RaceType] === 0);
  };
  function getChartData(select: string, objectChartData: any) {
    const raceData = objectChartData[select];
    let result = [];
    if (select === 'raceWA') {
      result =
        raceData === null
          ? [
              {
                name: 'Asian/Pacific Islander',
                value: 'N/A',
                valueAsian: 'N/A',
                valueIslander: 'N/A',
              },
              {
                name: 'Black/African-American',
                value: 'N/A',
              },
              {
                name: 'Hispanic/Latino',
                value: 'N/A',
              },
              {
                name: 'Multiple Races/Another Race',
                value: 'N/A',
                valueTwoPlus: 'N/A',
                valueOther: 'N/A',
              },
              {
                name: 'Native American',
                value: 'N/A',
              },
              {
                name: 'White',
                value: 'N/A',
              },
            ]
          : [
              {
                name: 'Asian/Pacific Islander',
                value: (
                  Math.round((raceData?.asian + raceData?.islander) * 100) / 100
                ).toFixed(2),
                valueAsian: (Math.round(raceData?.asian * 100) / 100).toFixed(
                  2
                ),
                valueIslander: (
                  Math.round(raceData?.islander * 100) / 100
                ).toFixed(2),
              },
              {
                name: 'Black/African-American',
                value: (Math.round(raceData?.black * 100) / 100).toFixed(2),
              },
              {
                name: 'Hispanic/Latino',
                value: (Math.round(raceData?.hispanic * 100) / 100).toFixed(2),
              },
              {
                name: 'Multiple Races/Another Race',
                value: (
                  Math.round((raceData?.other + raceData?.twoPlus) * 100) / 100
                ).toFixed(2),
                valueTwoPlus: (
                  Math.round(raceData?.twoPlus * 100) / 100
                ).toFixed(2),
                valueOther: (Math.round(raceData?.other * 100) / 100).toFixed(
                  2
                ),
              },
              {
                name: 'Native American',
                value: (Math.round(raceData?.native * 100) / 100).toFixed(2),
              },
              {
                name: 'White',
                value: (Math.round(raceData?.white * 100) / 100).toFixed(2),
              },
            ];
    } else {
      result =
        raceData === null
          ? [
              {
                name: 'Asian/Pacific Islander',
                value: 'N/A',
              },
              {
                name: 'Black/African-American',
                value: 'N/A',
              },
              {
                name: 'Hispanic/Latino',
                value: 'N/A',
              },
              {
                name: 'Multiple Races/Another Race',
                value: 'N/A',
              },
              {
                name: 'Native American',
                value: 'N/A',
              },
              {
                name: 'White',
                value: 'N/A',
              },
            ]
          : [
              {
                name: 'Asian/Pacific Islander',
                value: (
                  Math.round((raceData?.asian + raceData?.islander) * 100) / 100
                ).toFixed(2),
              },
              {
                name: 'Black/African-American',
                value: (Math.round(raceData?.black * 100) / 100).toFixed(2),
              },
              {
                name: 'Hispanic/Latino',
                value: (Math.round(raceData?.hispanic * 100) / 100).toFixed(2),
              },
              {
                name: 'Multiple Races/Another Race',
                value: (
                  Math.round((raceData?.other + raceData?.twoPlus) * 100) / 100
                ).toFixed(2),
              },
              {
                name: 'Native American',
                value: (Math.round(raceData?.native * 100) / 100).toFixed(2),
              },
              {
                name: 'White',
                value: (Math.round(raceData?.white * 100) / 100).toFixed(2),
              },
            ];
    }

    return result;
  }

  const options = {
    silent: true,
    color: colorPalette,
    textStyle: {
      fontFamily: 'Open Sans',
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#282828',
      borderColor: '#282828',
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        color: '#FFFFFF',
      },
      padding: 16,
      formatter(params: any) {
        const colorSpan = (color: any) =>
          `<span style="display:inline-block;margin-right:5px;border:1px #FFFFFF solid;border-radius:10px;width:9px;height:9px;background-color:${color}"></span>`;
        let rez = `<p style="font-size:18px;font-family: Open Sans;font-weight: 700;">${params[0].axisValue}</p>`;
        params.forEach((item: any) => {
          if (item.data.name === 'Asian/Pacific Islander') {
            let xx;
            if (selected === 'raceWA') {
              xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item.color
              )} ${
                item.seriesName
              }</p><div style="margin:2px;padding:0;"><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Asian: ${
                item.data?.valueAsian
              }%</li><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Pacific Islander: ${
                item.data?.valueIslander
              }%</li></div>`;
            } else {
              xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item.color
              )} ${
                item.seriesName
              }</p><div style="margin:2px;padding:0px"><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Asian or Pacific Islander: ${
                item.data?.value
              }%</li></div>`;
            }
            rez += xx;
          } else if (item.data.name === 'Multiple Races/Another Race') {
            let xx;
            if (selected === 'raceWA') {
              xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item.color
              )} ${
                item.seriesName
              }</p><div style="margin:2px;padding:0;"><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Other: ${
                item.data?.valueOther
              }%</li><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Two or more: ${
                item.data?.valueTwoPlus
              }%</li></div>`;
            } else {
              xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item?.color
              )} ${
                item?.seriesName
              }</p><div style="margin:2px;padding:0px"><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Other or Two or more: ${
                item.data?.value
              }%</li></div>`;
            }
            rez += xx;
          } else {
            const xx =
              `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item.color
              )} ${item.seriesName}: ${item.data.value}%` + '</p>';
            rez += xx;
          }
        });

        return rez;
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      rich: {
        a: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '12px',
          color: '#282828',
        },
        b: {
          padding: [0, 0, 6, 0],
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '26px',
          align: 'center',
          color: '#282828',
        },
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          borderColor: 'black',
          borderWidth: 5,
          opacity: 0.5,
        },
      },
    },
    title: {
      z: 10,
      text: null,
      left: 25,
      padding: 0,
      textStyle: {
        fontSize: 16,
        fontWeight: 600,
      },
    },
    legend: {
      show: null,
    },
    grid: {
      show: false,
      right: '0px',
      left: !isDataExist
        ? '-20px'
        : chartData[selected] === null && comparisonChartData.raceWA == null
        ? '0px'
        : arePropertiesZero(chartData[selected])
        ? `${isComparison ? '-21px' : '-23px'}`
        : '-20px',
      bottom: '6%',
      top: '12%',
      containLabel: true,
    },
    xAxis: [
      {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          boundaryGap: true,
          lineStyle: {
            color: '#EFF2F7',
            width: 2,
          },
        },
        type: 'category',
        data: [
          'Asian/Pacific \n\n Islander',
          'Black/African-\n\nAmerican',
          'Hispanic/\n\nLatino',
          'Multiple Races/\n\nAnother Race',
          'Native\n\n American',
          'White',
        ],
        axisLabel: {
          interval: 0,
        },
        offset: 8,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#7A89A7',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        splitLine: {
          show: false,
          boundaryGap: false,
        },
        type: 'value',
      },
    ],
    series: [
      {
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            color: 'rgba(33, 3, 3, 1)',
          },
        },
        barWidth: isDataExist ? !isComparison && '35%' : '35%',
        name: cuName,
        type: 'bar',
        label: {
          show: true,
          color: 'inherit',
          position: 'top',
          valueAnimation: true,
          formatter: '{@score}%',
        },
        data: getChartData(isDataExist ? selected : 'raceWA', chartData),
      },
      isComparison && {
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            color: 'rgba(33, 3, 3, 1)',
          },
        },
        name: comparisonName,
        type: 'bar',
        label: {
          show: true,
          color: 'inherit',
          position: 'top',
          valueAnimation: true,
          formatter: '{@score}%',
        },
        data: getChartData('raceWA', comparisonChartData),
      },
    ],
  };
  return (
    <>
      <div className={s.block}>
        <div
          className={s.title}
          style={{
            marginBottom: !isDataExist
              ? '8px'
              : selected === 'raceWA'
              ? '8px'
              : '0px',
          }}
        >
          <span>
            Race & Hispanic
            <span className={s.margin} />
          </span>
          <SelectComponent
            selected={selected}
            setSelected={handleSelected as any}
          />
        </div>
        {isDataExist &&
          (selected === 'raceWA' || (
            <div
              className={s.subTitle}
              style={{
                color:
                  chartData[selected]?.matchPercent <= 10
                    ? '#ED5050'
                    : '#232B95',
              }}
            >
              {Math.round(
                (chartData[selected]?.matchPercent * 100) / 100
              ).toFixed(0)}
              % Match Rate <span className={s.margin} />
              <CircleTooltip
                description={
                  <div className={`${s.tooltip}`}>
                    {methadologyDescription[selected]}
                  </div>
                }
                fullWidth
                width="490px"
              />
            </div>
          ))}
        <Legend
          cuName={cuName}
          cuNameColor={isDataExist ? '#232B95' : '#52B36B'}
          comparisonName={comparisonName}
        />
        <div className={s.chart}>
          <ReactECharts
            style={{ marginTop: '4px', height: '100%', width: '100%' }}
            option={options}
            notMerge={true}
            lazyUpdate={true}
            opts={{ renderer: 'canvas' }}
          />
        </div>
      </div>
    </>
  );
};

export default memo(RaceChart);
