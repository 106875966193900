import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from '../../style/ImpactReportPage.module.scss';
import s from './style/TotalBenefitsWidgetType.module.scss';
import { CircleTooltip } from '../../../../components';

const LoadingTotalBenefitsWidget: FC = () => (
  <div className={`${s.benefits}`}>
    <div className="col-sm-12">
      <h4 className={`${s.benefits__title}`}>
        Total Annual Benefits to Consumers
        <span className={`${styles.chartSubTitle__margin}`}>
          <CircleTooltip
            description={
              <div className={`${styles.tooltip__text}`}>
                Consumers includes credit union members, as well as non-members
                with the sum of (1) the dollar amount that credit union
                consumers save by having loans with interest rates that are
                lower than what borrowers with their credit histories would pay
                at other lenders and (2) the extra dollar amounts that credit
                union consumers receive by having deposits with interest rates
                that are higher than they would be at banks, each during the
                last available calendar year. Note that actual benefits are
                larger than our estimates, since we do not include, for
                instance, the benefits from lower fees on other financial
                products.
              </div>
            }
          />
        </span>
      </h4>
      <div className="row" style={{ height: '90%', alignContent: 'center' }}>
        <div className="col-6">
          <div className={s.benefits__member__value}>
            <Skeleton width="100%" height="90px" />
          </div>
          <div className={`${styles.loading__sm} ${s.benefits__member__info}`}>
            <Skeleton width="100%" height="100%" />
          </div>
        </div>
        <div className="col-6">
          <div className={s.benefits__member__value}>
            <Skeleton width="100%" height="90px" />
          </div>
          <div className={`${styles.loading__sm} ${s.benefits__member__info}`}>
            <Skeleton width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default LoadingTotalBenefitsWidget;
