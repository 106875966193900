import { UseQueryOptions } from 'react-query';
import httpClient from '../../../helpers/http';

export const GetPaymentIntent = ({
  amount,
  isCheckingBusinessAccountsSelected,
  isCheckingCollegeRange1Selected,
  isCheckingCollegeRange2Selected,
  isCheckingCollegeRange3Selected,
}: {
  amount: number;
  isCheckingBusinessAccountsSelected: boolean;
  isCheckingCollegeRange1Selected: boolean;
  isCheckingCollegeRange2Selected: boolean;
  isCheckingCollegeRange3Selected: boolean;
}): UseQueryOptions => ({
  queryKey: ['LID-get-payment-intent'],
  queryFn: () =>
    httpClient.get(
      `/api/lid/createpaymentintent?amount=${amount}&isCheckingBusinessAccountsSelected=${isCheckingBusinessAccountsSelected}&isCheckingCollegeRange1Selected=${isCheckingCollegeRange1Selected}&isCheckingCollegeRange2Selected=${isCheckingCollegeRange2Selected}&isCheckingCollegeRange3Selected=${isCheckingCollegeRange3Selected}`
    ),
});

export const GetBeforeProperties = (): UseQueryOptions => ({
  queryKey: ['LID-before-properties'],
  queryFn: () => httpClient.get('/api/lid/beforfeproperties'),
});

export const GetEnrichStatus = (): UseQueryOptions => ({
  queryKey: ['LID-enrich-status'],
  queryFn: () => httpClient.get('/api/lid/enrichmentstatus'),
});

export const GetCollegeEnrichData = (): UseQueryOptions => ({
  queryKey: ['LID-college-enrich-data'],
  queryFn: () => httpClient.get('/api/lid/getcollegeenrichmentdata'),
});

export const GetNotLIDMembersData = (): UseQueryOptions => ({
  queryKey: ['LID-no-members-data'],
  queryFn: () => httpClient.get('/api/lid/notlidmembersintoyes'),
});

export const GetLIDDonutChartData = (): UseQueryOptions => ({
  queryKey: ['LID-donut-chart-data'],
  queryFn: () => httpClient.get('/api/lid/donutchart'),
});

export const GetLIDLineChartData = (): UseQueryOptions => ({
  queryKey: ['LID-line0chart-data'],
  queryFn: () => httpClient.get('/api/lid/linechart'),
});

export const GetLIDStatsData = (): UseQueryOptions => ({
  queryKey: ['LID-stats-data'],
  queryFn: () => httpClient.get('/api/lid/stats'),
});
