import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import s from './Alert.module.scss';

type IAlertDialog = {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setContinue: Function;
};

const useStyles = makeStyles({
  cancelButton: {
    border: 'solid 2px #EFF2F7',
    width: '160px',
    height: '40px',
    '&:hover': {
      filter: 'drop-shadow(0px 2px 15px rgba(122, 137, 167, 0.25))',
      borderRadius: '4px!important',
      background: 'white!important',
      color: '#282828!important',
    },
  },
  submitButton: {
    color: '#ffff',
    width: '218px',
    height: '40px',
    backgroundColor: '#52B36B!important',
    '&:hover': {
      opacity: 0.8,
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
  },
  button: {
    height: '40px',
    '&:hover': {
      boxShadow: ' 0px 2px 10px 4px #C3C5E1',
      backgroundColor: ' rgba(43, 57, 185, 0.8)!important',
    },
  },
});

const AlertDialog: FC<IAlertDialog> = ({ isOpen, setContinue, setOpen }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{
        position: 'absolute',
        top: '25%',
      }}
    >
      <div className={s.modal}>
        <h2 className={s.modal__header}>Insufficient Data</h2>
        <p className={s.modal__content}>
          An insufficient number of Credit Unions have contributed AIRES files
          in your selected location for AnalyzeCU to be able to compute results
          for Credit Unions. You may want to consider a larger location
        </p>
        <div className={s.modal__footer}>
          <Button
            onClick={setContinue}
            className={classes.cancelButton}
            size="large"
            variant="contained"
            color="secondary"
            autoFocus
          >
            <span style={{ fontSize: '14px' }}> Proceed </span>
          </Button>
          <span
            className={s.modal__footer__margin}
            style={{ margin: '12px' }}
          />
          <Button
            onClick={setOpen}
            color="primary"
            className={classes.submitButton}
          >
            <span style={{ fontSize: '14px' }}> Choose other location </span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertDialog;
