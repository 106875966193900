/* eslint-disable */
import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from './style/NumberChartWidget.module.scss';
import s from '../../style/ImpactReportPage.module.scss';
import { CircleTooltip } from '../../../../components';

type LoadingNumberChartWidgetType = {
  title: string;
  tooltip?: string;
  isComparison?: boolean;
  withLabel?: boolean;
};
const LoadingNumberChartWidget: FC<LoadingNumberChartWidgetType> = ({
  title,
  tooltip,
  isComparison = true,
  withLabel = false,
}) => (
  <div className={`${s.chart}`}>
    <>
      <div className="col-3" style={{ paddingRight: 0 }}>
        <h4 className={s.chartSubTitle} style={{ height: '50px' }}>
          {title}
          {tooltip && (
            <span>
              <CircleTooltip
                fullWidth
                description={
                  <div className={`${s.tooltip__text}`}>{tooltip}</div>
                }
              />
            </span>
          )}
        </h4>
        <div
          className={`${s.loading__wrapper__block__item__left} ${styles.numberBlock__total}`}
        >
          <div className={styles.numberBlock__total__label}>
            <Skeleton height="90px" />
          </div>
          <div
            className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
          >
            <Skeleton height="100%" width="100%" />
          </div>
        </div>
      </div>
      <div className="col-9" style={{ padding: 0 }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ flex: 'auto' }}>
            <div
              className={`${s.loading__wrapper}`}
              style={{
                height: '50px',
                justifyContent: 'flex-end',
                marginRight: '20px',
              }}
            >
              <div className={`${s.loading__wrapper__legend}`}>
                <Skeleton width="100%" />
                {withLabel && <Skeleton width="100%" />}
                {isComparison && <Skeleton width="100%" />}
              </div>
            </div>

            <div
              className={`${styles.numberBlock__total} ${s.loading__wrapper__block} `}
            >
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  </div>
);

export default LoadingNumberChartWidget;
