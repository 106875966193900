/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const ImpactReportSlice = createSlice({
  name: 'ImpactReport',
  initialState: {
    isExit: false,
    isFirstOpen: true,
    isLeague: false,
    isModalOpen: false,
    allFilters: [],
    activeLocation: { value: 'All Geographies', locationType: 1132 },
    tempLocation: { value: 'All Geographies', locationType: 1132 },
    tagsData: [{ value: 'United States', entityId: '31893480' }],
    activeTagsData: [{ value: 'United States', entityId: '31893480' }],
  },
  reducers: {
    setTempLocation: (
      state: any,
      action: PayloadAction<{ value: string; locationType: number }>
    ) => {
      state.tempLocation = action.payload as any;
      state.isExit = true;
      if (state.tempLocation.value == 'All Geographies') {
        state.tagsData = [{ value: 'United States', entityId: '31893480' }];
      }
    },
    setIsLeagueGlobal: (state: any) => {
      state.activeTagsData = [];
      state.isLeague = true;
    },
    setIsFirstOpen: (state: any) => {
      state.isFirstOpen = false;
    },
    resetTags: (state: any) => {
      if (state.isExit) {
        state.tagsData = [];
        if (state.tempLocation.value == 'All Geographies') {
          state.tagsData = [{ value: 'United States', entityId: '31893480' }];
        }
      }
    },
    setIsModalOpen: (state: any, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    setAllFilters: (state: any, action: PayloadAction<any>) => {
      state.allFilters = action.payload;
    },
    setActive: (state: any) =>
      void ((state.activeLocation = state.tempLocation),
      state.tempLocation.value == 'All Geographies'
        ? (state.activeTagsData = [
            { value: 'United States', entityId: '31893480' },
          ])
        : (state.activeTagsData = state.tagsData),
      (state.isExit = false),
      (state.isModalOpen = false)),
    setTemp: (state: any) =>
      void ((state.tempLocation = state.activeLocation),
      (state.tagsData = state.activeTagsData),
      (state.isExit = false)),
    removeTag: (state: any, action: PayloadAction<{ tagId: number }>) => {
      state.tagsData = [
        ...state.tagsData.filter(
          (_: any, index: any) => index !== action.payload.tagId
        ),
      ];
    },
    addTags: (
      state: any,
      action: PayloadAction<{
        tagId: number;
        tagName: string;
      }>
    ) => {
      if (
        state.tagsData.find(
          (item: any) => item.value === action.payload.tagName
        )
      ) {
        return;
      }
      state.tagsData.push({
        entityId: action.payload.tagId,
        value: action.payload.tagName,
      });
    },
    setTempTags: (
      state: any,
      action: PayloadAction<
        {
          entityId: string;
          value: string;
        }[]
      >
    ) => {
      state.tagsData = action.payload;
    },
  },
});

export default ImpactReportSlice.reducer;
export const {
  setTemp,
  setActive,
  removeTag,
  resetTags,
  setTempLocation,
  setAllFilters,
  setIsLeagueGlobal,
  setIsFirstOpen,
  setIsModalOpen,
  addTags,
  setTempTags,
} = ImpactReportSlice.actions;
