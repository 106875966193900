/* eslint-disable */
import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import s from './FileUpload.module.scss';
import acmdIcon from './assets/acmd.svg';
import reloadIcon from './assets/reload.svg';
import deleteIcon from './assets/delete.svg';

export interface FileUploadProps {
  onDrop: any;
  accept: string | string[];
  fileName: string;
  isDropAccepted: boolean;
  removeFile?: () => void;
}

const FileUpload: FC<FileUploadProps> = ({
  onDrop,
  accept,
  fileName,
  isDropAccepted,
  removeFile,
}) => {
  const getClassName = (className: string, isActive: boolean): string => {
    if (!isActive) return className;
    return `${className} ${className}-active`;
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <div
      className={getClassName(s.dropzone__wrapper, isDragActive)}
      {...(!isDropAccepted && { ...getRootProps() })}
      style={{ border: `${isDropAccepted && 'solid 1px rgba(0, 0, 0, 0.23)'}` }}
    >
      <input title="file input" {...getInputProps()} />
      {isDropAccepted && (
        <div className="container p-0">
          <span>
            <img
              style={{ width: '32px', height: '100%' }}
              src={acmdIcon}
              alt="File icon"
            />
          </span>
          <span className={`${s.dropzone__fileName}`}>{fileName}</span>
          <span className={`${s.dropzone__action}`}>
            <span>
              <img
                style={{ outline: 'none' }}
                onClick={removeFile}
                src={deleteIcon}
                alt="Delete icon"
              />
            </span>
            {/* <span  className={`${s.dropzone__icon}`}>
              <img
                {...isDropAccepted && { ...getRootProps() }}
                src={reloadIcon}
                style={{ outline: 'none' }}
                alt="Reload icon"
              />
            </span> */}
          </span>
        </div>
      )}
      <div className="text-center ">
        <div className={`${'dropzone-content' + ' row'}`}>
          {!isDropAccepted && (
            <div className="container">
              <h3 className={`${s.dropzone__info}`}>
                Drag & drop or{' '}
                <span style={{ textDecoration: 'underline' }}>browse</span>
              </h3>
              <p className={`${s.dropzone__format}`}>File should be .acmd</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default FileUpload;
