/* eslint-disable */
import React, { FC } from 'react';
import ReactECharts from 'echarts-for-react';

interface DataItem {
  name: String;
  value: any[];
}

interface DataSeries {
  data: DataItem[];
  lineStyle: any;
}

export interface PieChartProps {
  pieData: object[];
  pieTitle: string;
  cuName?: string;
  pieHeight?: number;
  styleProperty?: any;
}

const Pie: FC<PieChartProps> = ({
  pieData,
  pieTitle,
  cuName,
  pieHeight = 226,
  styleProperty = { width: '100%' },
}) => {
  const colorPalette = [
    '#232B95',
    '#EE6A2C',
    '#917FFF',
    '#52B36B',
    '#5C6BC8',
    '#5890FF',
  ];

  const options = {
    fontFamily: 'Open Sans',
    tooltip: {
      trigger: 'item',
      backgroundColor: '#282828',
      borderColor: '#282828',
      textStyle: {
        color: '#FFFFFF',
      },
      padding: 16,
      formatter: function (params: any) {
        let pieName = cuName ? params.name : params.seriesName;
        let itemName = cuName ? cuName : params.data.name;
        var colorSpan = (color: any) =>
          '<span style="display:inline-block;margin-right:5px;border:1px #FFFFFF solid;border-radius:10px;width:9px;height:9px;background-color:' +
          color +
          '"></span>';
        let rez =
          '<p style="font-size:18px;font-weight: 700;">' + pieName + '</p>';
        var xx =
          '<p style="padding:0;margin:0">' +
          colorSpan(params.data.name1 ? '#232B95' : params.color) +
          '' +
          itemName +
          ': ' +
          params.data.value +
          '%' +
          '</p>';
        rez += xx;
        var ss = cuName
          ? '<p style="padding:0;margin:0">' +
            colorSpan('#7BD5A7') +
            '' +
            params.data.name1 +
            ': ' +
            params.data.value1 +
            ' % ' +
            '</p > '
          : '';
        return (rez += ss);
      },
      position: function (
        point: any,
        params: any,
        dom: any,
        rect: any,
        size: any
      ) {
        // The tooltip is displayed to the right when the mouse is on the left, and the tooltip is displayed to the left when the mouse is on the right
        let obj: any = { top: point[1] };
        obj[['left', 'right'][+(point[0] < size.viewSize[0] / 5)]] = 5;
        return obj;
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          borderColor: 'black',
          borderWidth: 5,
          opacity: 0.5,
        },
      },
    },
    legend: {
      type: 'scroll',
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '12px',
        color: '#282828',
      },
      top: 'middle',
      left: '56%',
      orient: 'vertical',
      icon: 'circle',
      align: 'left',
      itemGap: 16,
    },

    color: colorPalette,

    series: [
      {
        name: pieTitle,
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        legendHoverLink: false,
        center: ['29%', '50%'],
        labelLayout: {
          hideOverlap: true,
        },

        label: {
          normal: {
            width: '90',
            border: 100,
            show: true,
            position: 'center',
            formatter: '{b|{c}%}\n{a|{b}}',
            rich: {
              a: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontStyle: 'normal',
                fontSize: '12px',
                color: '#282828',
              },
              b: {
                padding: [0, 0, 6, 0],
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '26px',
                align: 'center',
                color: '#282828',
              },
            },
          },
          emphasis: {
            focus: 'series',
            blurScope: 'coordinateSystem',
            width: '90',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            show: true,
            formatter: '{b|{c}%}\n{a|{b}}',
            rich: {
              a: {
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '12px',
                color: '#282828',
              },
              b: {
                padding: [0, 0, 6, 0],
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '26px',
                align: 'center',
                color: '#282828',
              },
            },
          },
        },
        itemStyle: {
          normal: {
            label: {
              show: false,
            },
          },
          emphasis: {
            label: {
              show: true,
            },
          },
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '10',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: pieData,
      },
    ],
  };

  return (
    <div
      style={{
        ...styleProperty,
        height: pieHeight + 'px',
        maxHeight: '100%',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ flex: 'auto' }}>
        <ReactECharts
          style={{
            padding: '4px',
            height: pieHeight + 'px',
            maxHeight: '100%',
            width: '100%',
          }}
          option={options}
          opts={{ renderer: 'canvas' }}
        />
      </div>
    </div>
  );
};
export default Pie;
