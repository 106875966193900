import React, { FC } from 'react';
import styles from './styles/UnionImpact.module.scss';

interface UnionImpactType {
  AreaName: string;
  cuName: string;
}
const UnionImpact: FC<UnionImpactType> = ({ cuName, AreaName }) => (
  <div className={`${styles.informationBlock}`}>
    <p className={`${styles.informationBlock__text}`}>
      {cuName}
      <span className={styles.informationBlock__impact}>
        {' '}
        Impact {AreaName.indexOf('selected communities') !== -1 ? 'on' : 'in'}
      </span>
      <span className={styles.informationBlock__area}>{` ${AreaName}`}</span>
    </p>
  </div>
);

export default UnionImpact;
