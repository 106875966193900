import { UseQueryOptions } from 'react-query';
import httpClient from '../../../helpers/http';

export * from './LoanTab.endpoints';
export * from './ShareTab.endpoints';
export * from './PFCtab.endpoints';

export const GetMappingStatus = ({
  isRefetch,
}: {
  isRefetch: boolean;
}): UseQueryOptions => ({
  queryKey: ['Aries-Map-Status', isRefetch],
  queryFn: () => httpClient.get('api/datamanagement/airesmappingstatus'),
  refetchInterval: isRefetch ? 3700 : false,
});

export const GetIsAreaEmpty = ({
  isRefetch,
}: {
  isRefetch: boolean;
}): UseQueryOptions => ({
  queryKey: [`Datamanagement-aries-file-exist-${isRefetch || ''}`],
  queryFn: () => httpClient.get('api/datamanagement/airesfilesisempty'),
  refetchInterval: isRefetch ? 3700 : false,
});

export const GetAreExistData = ({
  isRefetch,
}: {
  isRefetch: boolean;
}): UseQueryOptions => ({
  queryKey: [`Datamanagement-are-exist-Loan-Share-${isRefetch || ''}`],
  queryFn: () => httpClient.get('api/datamanagement/areexistloanandsharefiles'),
  refetchInterval: isRefetch ? 800 : false,
});

type GetAiresFilesTableDataArgs = {
  orderByProperty?: string;
  orderBy?: 0 | 1 | 2;
  take?: number;
  page?: number;
};

export const generateAiresFilesTableDataKey = (page: number | undefined) => [
  'Aires-files-table-data',
  page,
];

export const GetAiresFilesTableData = ({
  orderByProperty,
  orderBy,
  take,
  page,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
GetAiresFilesTableDataArgs): UseQueryOptions<any> => ({
  queryKey: generateAiresFilesTableDataKey(page),
  queryFn: () =>
    httpClient.get(
      `/api/datamanagement/airesfiles?${page ? `page=${page}` : ''}${
        take ? `&take=${take}` : ''
      }&orderByProperty=${orderByProperty}&orderBy=${orderBy}`
    ),
  refetchOnWindowFocus: false,
  cacheTime: 0,
  staleTime: 0,
});

export const DeleteMemberDataRow = ({ deleteId }: { deleteId: number }) =>
  httpClient.delete(`/api/datamanagement/memberdata/${deleteId}`);
