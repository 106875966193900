import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import React, { Component, ReactNode, ErrorInfo } from 'react';
import { reactPlugin, appInsights } from '../../helpers/AppInsights';
import { sendGlobalData } from './functions/sendGlobalData';
import { ErrorPage } from './ErrorPage';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    if (process.env.REACT_APP_ENV == '1') {
      appInsights.loadAppInsights();
      console.log('init');
    }
  }

  public static getDerivedStateFromError(_: Error): State {
    console.log('Error:', _);
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log('Uncaught error:', error, errorInfo);
    sendGlobalData({
      errorType: 'generalError',
    });
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    return (
      <>
        {process.env.REACT_APP_ENV == '1' ? (
          <AppInsightsErrorBoundary
            onError={() => <ErrorPage />}
            appInsights={reactPlugin}
          >
            <>{this.state.hasError ? <ErrorPage /> : this.props.children}</>
          </AppInsightsErrorBoundary>
        ) : (
          this.props.children
        )}
      </>
    );
  }
}
