/* eslint-disable */
import React, { FC } from 'react';
import s from './style/InfoBlock.module.scss';
import { CircleTooltip } from '../CircleTooltip';

interface InfoBlockType {
  description?: string | React.ReactElement;
  title?: string | React.ReactElement;
  medianAge: number | string;
  medianComparisonAge?: string | undefined | null | React.ReactElement;
  numberDescription: string | undefined | null | React.ReactElement;
  icon?: string;
  numberDescriptionStyle?: string;
  prefix?: string;
  tooltipText?: string | React.ReactElement;
}

const InfoBlock: FC<InfoBlockType> = ({
  description,
  title,
  icon,
  medianAge,
  numberDescription,
  numberDescriptionStyle = '200px',
  medianComparisonAge,
  prefix,
  tooltipText,
}) => {
  return (
    <div>
      {title && (
        <h4 className={`${s.chartSubTitle}`}>
          {title}
          {description && (
            <span className={`${s.chartSubTitle__margin}`}>
              <CircleTooltip description={description} />
            </span>
          )}
        </h4>
      )}

      <div className={`${s.wrapper}`}>
        <div className={`${s.info__age}`}>
          {icon && (
            <div>
              <img
                className={`${s.info__age__image}`}
                src={icon}
                alt="block icon"
              />
            </div>
          )}
          <div>
            <span className={`${s.info__age__prefix}`}>{medianAge}</span>
            {prefix && (
              <span className={`${s.info__age__postfix}`}>{prefix}</span>
            )}
            <div
              className={`${s.info__data}`}
              style={{ width: numberDescriptionStyle }}
            >
              {numberDescription}
              {tooltipText && (
                <span style={{ marginLeft: '4px' }}>
                  <CircleTooltip description={tooltipText} />
                </span>
              )}
            </div>
          </div>
        </div>
        {medianComparisonAge && (
          <div className={`${s.info}`}>
            <div className={`${s.info__descriptionText}`}>Comparison</div>
            <div
              className={`${s.info__data}`}
              style={{ width: numberDescriptionStyle }}
            >
              {medianComparisonAge}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoBlock;
