/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import ExportHeader from './ExportHeader';
import abbreviateNumber from '../../../../utils/functions/abbreviateNumber';
import Legend from '../../../../components/Legend';
import Chart from './NumberChartWidget/Chart';
import s from './Export.module.scss';
import ExportRaceChart from '../RaceChart/ExportRaceChart';

type ChartData = { valueCount: number; value: string }[];

const methadologyMatchRateDescription = {
  raceBIFSG:
    'The match rate for the BIFSG model represents the number of consumers we were able to estimate race/ethnicity for by using their first name, last name, and location. Unmatched consumers have uncommon first and/or last names. Match rates above 10% produce estimates that are considered reliable. ',
  raceBISG:
    'The match rate for the BISG model represents how many consumers we were able to estimate race/ethnicity for by using their last name and location. Unmatched consumers have uncommon last names. Match rates above 10% produce estimates that are considered reliable.',
};

const methodology = {
  raceBIFSG: 'BIFSG Methodology',
  raceBISG: 'BISG Methodology (Fair Lending)',
  raceWA: 'Weighted Average of Census Block Groups',
};
const methadologyDescription = {
  raceBIFSG:
    'The Bayesian Improved First Name Surname Geocoding (BIFSG) methodology estimates are based on the race and ethnicity distributions of the census block groups in which a consumer lives, their last name, and their first name.',
  raceBISG:
    'The Bayesian Improved Surname Geocoding (BISG) methodology estimates are based on the race and ethnicity distributions of both the census block groups in which a consumer lives and their last name. This methodology was first developed by the Rand Corporation and is used by the Consumer Financial Protection Bureau (CFPB) to assess compliance with fair lending laws.',
  raceWA:
    'Estimates are a weighted average of the race and ethnicity distribution of the census block groups in which consumers reside.',
};

type RaceType = {
  white: number;
  black: number;
  native: number;
  asian: number;
  islander: number;
  other: number;
  twoPlus: number;
  hispanic: number;
  matchPercent: number | null;
};

type RaceChartType = {
  raceWA: null | RaceType;
  raceBISG: null | RaceType;
  raceBIFSG: null | RaceType;
};
type MyDocumentProps = {
  selected: string;
  refs: React.LegacyRef<HTMLDivElement>;
  data: {
    isTBTMEnabled: boolean;
    areaData: {
      members: number;
      loans: number;
      population: number;
    };
    totalBenefits: {
      total: number;
      benefits: number;
      members: number;
    };
    sustainedCreated: {
      totalJobs: number;
      totalDirect: number;
      totalContribution: number;
    };
    charts: {
      race: {
        primaryData: RaceChartType;
        comparisonData: RaceChartType;
      };
      areaData: {
        name: string;
        members: ChartData;
        loans: ChartData;
        population: ChartData;
      };
      income: {
        median: number;
        primaryData: string[];
        comparisonData: string[];
      };
      creditScore: {
        median: number;
        primaryData: string[];
      };
    };
  };

  cuInfo: {
    shippingAddress: string;
    billingAddress: string;
    email: string;
    description: string;
    logoUrl: string;
    cuName: string;
  };
  impactIn: string;
  isLeague: boolean;
  title: string;
};

const MyDocument: FC<MyDocumentProps> = ({
  refs,
  isLeague,
  data,
  cuInfo,
  impactIn,
  title,
  selected,
}) => {
  const { areaData, totalBenefits, isTBTMEnabled, sustainedCreated, charts } =
    data;
  const LegendArray = [
    { name: `${charts.areaData.name}`, color: '#4388F6' },
    {
      name: `${charts.areaData.name.replace('consumers', 'loans')}`,
      color: '#232B95',
    },
  ];

  const LegendArray2 = [
    { name: `${cuInfo.cuName}`, color: '#232b95' },
    { name: 'Selected Area', color: '#52b36b' },
  ];

  return (
    <div ref={refs} style={{ padding: '20px' }} className="print__hide">
      <div className={s.page} style={{ height: '1054px' }}>
        <ExportHeader
          image={cuInfo.logoUrl}
          cuText={cuInfo.cuName}
          impactIn={impactIn}
        />
        <div style={{ marginTop: '12px' }} />
        {isTBTMEnabled ? (
          <div className={`${s.block} ${s.number}`}>
            <div className={s.number__element}>
              <h2 className={s.number__element__title}>{title}</h2>
              <h4
                className={s.number__element__value}
                style={{ fontSize: '30px' }}
              >
                {`$${abbreviateNumber(totalBenefits.total)}`}
              </h4>
            </div>
            <div className={s.number__element}>
              <div className={s.number__element__title} />
              <h5 className={s.number__element__value}>
                {`$${abbreviateNumber(totalBenefits.benefits)}`}
              </h5>
              <span className={s.number__element__text}>
                Annual Benefit per Consumer
              </span>
            </div>
          </div>
        ) : (
          ''
        )}
        <div style={{ marginTop: '12px' }} />
        <div className={`${s.block} ${s.number}`}>
          <div className={s.number__element}>
            <h2 className={s.number__element__title}>
              Jobs Sustained & Created
            </h2>
            <h4 className={s.number__element__value}>
              {abbreviateNumber(sustainedCreated.totalJobs)}
            </h4>
            <span className={s.number__element__text}>Total Jobs</span>
          </div>
          <div className={s.number__element}>
            <div className={s.number__element__title} />
            <h5 className={s.number__element__value}>
              {abbreviateNumber(sustainedCreated.totalDirect)}
            </h5>
            <span className={s.number__element__text}>Direct Jobs</span>
          </div>
          <div className={s.number__element}>
            <h2 className={s.number__element__title}>Economic Contribution</h2>
            <h5 className={s.number__element__value}>
              {`$${abbreviateNumber(sustainedCreated.totalContribution)}`}
            </h5>
            <span className={s.number__element__text}>Total Contribution</span>
          </div>
        </div>
        <div style={{ marginTop: '12px' }} />
        <div className={s.split}>
          <div className={s.split__element}>
            <h4 className={s.split__element__title}>Total Consumers</h4>
            <p className={s.split__element__value}>
              {abbreviateNumber(totalBenefits.members)}
            </p>
          </div>
          <div className={s.split__element}>
            <h4 className={s.split__element__title}>Number of Loans</h4>
            <p className={s.split__element__value}>
              {abbreviateNumber(areaData.loans)}
            </p>
          </div>
          <div className={s.split__element}>
            <div>
              <h4 className={s.split__element__title}>Population</h4>
              <p className={s.split__element__text}>In th selected area</p>
            </div>
            <p className={s.split__element__value}>
              {abbreviateNumber(areaData.population)}
            </p>
          </div>
        </div>
        <div style={{ marginTop: '12px' }} />
        <div className={`${s.block}`} style={{ padding: '12px 14px 0 0' }}>
          <div className={`${s.legend}`} style={{ marginBottom: '-15px' }}>
            <div className={`${s.legend__wrapper}`}>
              <h3 className={`${s.legend__wrapper__title}`}>By Area Type</h3>
            </div>
            <div className={`${s.legend__wrapper__legend}`}>
              <Legend
                cuName={LegendArray}
                comparisonName="Population in the selected area"
              />
            </div>
          </div>
          <Chart
            width="756px"
            cuName={cuInfo.cuName}
            data={[
              {
                barWidth: '22%',
                emphasis: {
                  focus: 'series',
                },
                blur: {
                  itemStyle: {
                    color: 'rgba(33, 3, 3, 1)',
                  },
                },
                name: LegendArray[0].name,
                type: 'bar',
                label: {
                  fontSize: 8,
                  show: true,
                  color: 'inherit',
                  position: 'top',
                  valueAnimation: true,
                  formatter: '{@score}%',
                },
                data: charts.areaData.population,
              },
              {
                barWidth: '22%',
                emphasis: {
                  focus: 'series',
                },
                blur: {
                  itemStyle: {
                    color: 'rgba(33, 3, 3, 1)',
                  },
                },
                name: LegendArray[1].name,
                type: 'bar',
                label: {
                  fontSize: 8,
                  show: true,
                  color: 'inherit',
                  position: 'top',
                  valueAnimation: true,
                  formatter: '{@score}%',
                },
                data: charts.areaData.loans,
              },
              {
                barWidth: '22%',
                emphasis: {
                  focus: 'series',
                },
                blur: {
                  itemStyle: {
                    color: 'rgba(33, 3, 3, 1)',
                  },
                },
                name: 'Population in the selected area',
                type: 'bar',
                label: {
                  fontSize: 8,
                  show: true,
                  color: 'inherit',
                  position: 'top',
                  valueAnimation: true,
                  formatter: '{@score}%',
                },
                data: charts.areaData.members,
              },
            ]}
            colorPalette={['#4388F6', '#232B95', '#52B36B']}
            title={[
              {
                value: 'Rural',
                textStyle: {
                  fontSize: 8,
                },
              },
              {
                value: 'Economically Distressed',
                textStyle: {
                  fontSize: 8,
                },
              },
              {
                value: 'Economically Distressed \n\n  & Rural',
                textStyle: {
                  fontSize: 8,
                },
              },
              {
                value: 'Neither Economically\n\n  Distressed nor Rural',
                textStyle: {
                  fontSize: 8,
                },
              },
            ]}
          />
        </div>
        <div style={{ marginTop: '12px' }} />
        <div className={`${s.block}`} style={{ padding: '12px 14px 6px 0' }}>
          <div className={`${s.legend}`} style={{ marginBottom: '-15px' }}>
            <div className={`${s.legend__wrapper}`}>
              <h3 className={`${s.legend__wrapper__title}`}>
                Income distribution
              </h3>
            </div>
            <div className={`${s.legend__wrapper__legend}`}>
              <Legend cuName={cuInfo.cuName} comparisonName="Selected Area" />
            </div>
          </div>
          <div className={s.chart}>
            <div className={s.chart__block}>
              <h5 className={s.chart__block__title}>Median household income</h5>
              <p className={s.chart__block__value}>
                ${abbreviateNumber(charts.income.median)}
              </p>
            </div>
            <Chart
              width="616px"
              cuName={cuInfo.cuName}
              title={[
                {
                  value: '$0-$25K',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: '$25-$50K',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: '$50K-$75K',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: '$75K-100K',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: '$100k-$150K',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: 'Over $150K',
                  textStyle: {
                    fontSize: 8,
                  },
                },
              ]}
              colorPalette={['#232B95', '#52B36B']}
              data={[
                {
                  barWidth: '35%',
                  emphasis: {
                    focus: 'series',
                  },
                  blur: {
                    itemStyle: {
                      color: 'rgba(33, 3, 3, 1)',
                    },
                  },
                  name: LegendArray2[0].name,
                  type: 'bar',
                  label: {
                    fontSize: 8,
                    show: true,
                    color: 'inherit',
                    position: 'top',
                    valueAnimation: true,
                    formatter: '{@score}%',
                  },
                  data: charts.income.primaryData,
                },
                {
                  barWidth: '35%',
                  emphasis: {
                    focus: 'series',
                  },
                  blur: {
                    itemStyle: {
                      color: 'rgba(33, 3, 3, 1)',
                    },
                  },
                  name: LegendArray2[1].name,
                  type: 'bar',
                  label: {
                    fontSize: 8,
                    show: true,
                    color: 'inherit',
                    position: 'top',
                    valueAnimation: true,
                    formatter: '{@score}%',
                  },
                  data: charts.income.comparisonData,
                },
              ]}
            />
          </div>
        </div>
        <div style={{ marginTop: '12px' }} />
        <div className={`${s.block}`} style={{ padding: '12px 14px 6px 0' }}>
          <div className={`${s.legend}`} style={{ marginBottom: '-15px' }}>
            <div className={`${s.legend__wrapper}`}>
              <h3 className={`${s.legend__wrapper__title}`}>
                Credit Score Tiers{' '}
              </h3>
            </div>
            <div className={`${s.legend__wrapper__legend}`}>
              <Legend cuName={cuInfo.cuName} comparisonName="No Comparison" />
            </div>
          </div>
          <div className={s.chart}>
            <div className={s.chart__block}>
              <h5 className={s.chart__block__title}>Median credit score</h5>
              <p className={s.chart__block__value}>
                {abbreviateNumber(charts.creditScore.median)}
              </p>
            </div>
            <Chart
              width="616px"
              cuName={cuInfo.cuName}
              title={[
                {
                  value: 'Poor: 300-579',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: 'Fair: 580-669',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: 'Good: 670-739',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: 'Very good: 740-799',
                  textStyle: {
                    fontSize: 8,
                  },
                },
                {
                  value: 'Exceptional: 800-850',
                  textStyle: {
                    fontSize: 8,
                  },
                },
              ]}
              colorPalette={['#232B95', '#52B36B']}
              data={[
                {
                  barWidth: '35%',
                  emphasis: {
                    focus: 'series',
                  },
                  blur: {
                    itemStyle: {
                      color: 'rgba(33, 3, 3, 1)',
                    },
                  },
                  name: LegendArray2[0].name,
                  type: 'bar',
                  label: {
                    fontSize: 8,
                    show: true,
                    color: 'inherit',
                    position: 'top',
                    valueAnimation: true,
                    formatter: '{@score}%',
                  },
                  data: charts.creditScore.primaryData,
                },
              ]}
            />
          </div>
        </div>
        <div style={{ marginTop: '12px' }} />
        <div className={`${s.block}`} style={{ padding: '12px 20px 0 20px' }}>
          <ExportRaceChart
            cuName={cuInfo.cuName}
            comparisonName="Selected Area"
            isComparison={true}
            selected={selected}
            chartData={charts.race.primaryData}
            comparisonChartData={charts.race.comparisonData}
          />
        </div>

        <div style={{ marginTop: '12px' }} />
      </div>

      <div className={s.page}>
        <ExportHeader
          image={cuInfo.logoUrl}
          cuText={cuInfo.cuName}
          impactIn={impactIn}
        />
        <div style={{ marginTop: '12px' }} />
        <div className={`${s.block} ${s.tooltip}`}>
          <h1 className={s.block__title}>Address</h1>
          <div style={{ marginTop: '6px' }} />
          <p className={s.block__text}>
            <span style={{ color: '#232B95' }}>Physical Address: </span>
            {cuInfo.shippingAddress}
          </p>
          <div style={{ marginTop: '6px' }} />
          <p className={s.block__text}>
            <span style={{ color: '#232B95' }}>Mailing Address: </span>
            {cuInfo.billingAddress}
          </p>
          <div style={{ marginTop: '6px' }} />
          <p className={s.block__text}>
            <span style={{ color: '#232B95' }}>Email: </span>
            <span style={{ textDecorationLine: 'underline' }}>
              {cuInfo.email}
            </span>
          </p>
        </div>
        <div className={s.padding} />
        <div className={`${s.block} ${s.tooltip}`}>
          <h1 className={s.block__title}>Company Description</h1>
          <div style={{ marginTop: '6px' }} />
          <p className={s.tooltip__block__text}>{cuInfo.description}</p>
        </div>
        <div className={s.padding} />
        <div className={`${s.block} ${s.tooltip}`}>
          <h1 className={s.block__title}>Methodologies</h1>
          <div style={{ marginTop: '6px' }} />
          {isTBTMEnabled ? (
            <>
              <div className={s.tooltip__block}>
                <h3 className={s.tooltip__block__title}>{title}</h3>
                <p className={s.tooltip__block__text}>
                  Consumers includes credit union members, as well as
                  non-members with the sum of (1) the dollar amount that credit
                  union consumers save by having loans with interest rates that
                  are lower than what borrowers with their credit histories
                  would pay at other banks and (2) the extra dollar amounts that
                  credit union consumers receive by having deposits with
                  interest rates that are higher than they would be at banks,
                  each during the last available calendar year. Note that actual
                  benefits are larger than our estimates, since we do not
                  include, for instance, the benefits from lower fees on other
                  financial products.
                </p>
              </div>
              <div style={{ marginTop: '20px' }} />
              <div className={s.tooltip__block}>
                <h3 className={s.tooltip__block__title}>
                  Annual Benefit per Consumer
                </h3>
                <p className={s.tooltip__block__text}>
                  Annual benefits are only computed for consumers for which we
                  have complete data.
                </p>
              </div>
              <div style={{ marginTop: '20px' }} />
            </>
          ) : (
            ''
          )}
          <div className={s.tooltip__block}>
            <h3 className={s.tooltip__block__title}>
              Jobs Sustained & Created
            </h3>
            <p className={s.tooltip__block__text}>
              The sum of (1) credit union employees (2) the jobs at credit union
              suppliers, supported by credit union expenses, (3) jobs at
              businesses patroned by credit union consumers, supported by
              dividends paid to consumers, and (4) jobs at businesses benefiting
              from credit union lending, each at the end of the last available
              calendar year.
            </p>
          </div>
          <div style={{ marginTop: '20px' }} />
          <div className={s.tooltip__block}>
            <h3 className={s.tooltip__block__title}>Economic Contribution</h3>
            <p className={s.tooltip__block__text}>
              The sum of (1) compensation paid to credit union employees, (2)
              revenues paid to credit union suppliers, (3) revenues received by
              businesses patroned by credit union consumers, supported by
              dividends paid to consumers, and (4) increases in credit unions'
              net worth, each during the last available calendar year. Note that
              actual economic contributions are larger than our estimates, since
              here we do not include, for instance, benefits to consumers and
              communities through credit union lending, volunteering, and
              community outreach.
            </p>
          </div>
          {isLeague && (
            <>
              {' '}
              <div style={{ marginTop: '20px' }} />
              <div className={s.tooltip__block}>
                <h3 className={s.tooltip__block__title}>Number of Loans</h3>
                <p className={s.tooltip__block__text}>
                  Our estimated number of loans does not sum to the total number
                  of loans for credit unions headquartered in that area. Instead
                  it is based on estimates of loans in individual branches,
                  adjusting for the possibility of out of state branches.
                </p>
              </div>
            </>
          )}
          {totalBenefits.members !== 0 ? (
            <>
              <div style={{ marginTop: '20px' }} />
              <div className={s.tooltip__block}>
                <h3 className={s.tooltip__block__title}>Total Consumers</h3>
                <p className={s.tooltip__block__text}>
                  {isLeague
                    ? 'Our estimated number of consumers does not sum to the total number of consumers for credit unions headquartered in that area. Instead it is based on estimates of consumers in individual branches, adjusting for the possibility of out of state branches.'
                    : 'Consumers includes credit union members, as well as non-members with loans and deposits listed in AIRES filesConsumers includes credit union members, as well as non-members with loans and deposits listed in AIRES files'}
                </p>
              </div>
            </>
          ) : (
            ''
          )}
          <div style={{ marginTop: '20px' }} />
          <div className={s.tooltip__block}>
            <h3 className={s.tooltip__block__title} style={{ marginTop: 0 }}>
              Credit Score Tiers
            </h3>
            <p className={s.tooltip__block__text}>
              Percentage of loans across consumers' credit score tiers. These
              percentages are computed dividing by the total number of
              outstanding loans (including undrawn lines of credit), not of
              consumers or of borrowers. Some consumers may have zero loans and
              other consumers may have more than one loan. These percentages are
              also not computed based on the dollar value of loans. Many
              reported loans (e.g., lines of credit for credit cards, overdraft
              protection, and home equity loans) have zero balances much of the
              time.
            </p>
          </div>
          <div style={{ marginTop: '20px' }} />
          <div className={s.tooltip__block}>
            <h3 className={s.tooltip__block__title} style={{ margin: 0 }}>
              Race & Hispanic Origin
            </h3>
            <h4 style={{ marginBottom: '4px' }}>
              <span style={{ fontSize: '8px', color: '#7A89A7' }}>
                Methodology:{' '}
                <span style={{ color: '#232b95' }}>
                  {methodology[selected as any]}
                </span>
              </span>
            </h4>
            <p className={s.tooltip__block__text}>
              {methadologyDescription[selected]}
            </p>
            {selected === 'raceWA' || (
              <>
                {' '}
                <h4 style={{ marginBottom: '4px' }}>
                  <span style={{ fontSize: '8px', color: '#7A89A7' }}>
                    Match Rate:
                  </span>
                </h4>
                <p className={s.tooltip__block__text}>
                  {methadologyMatchRateDescription[selected]}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDocument;
