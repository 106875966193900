/* eslint-disable */
import React, { FC } from 'react';

type FileIconComponentType = {
  color?: string;
  width?: string;
  height?: string;
};

const CheckCircleComponent: FC<FileIconComponentType> = ({
  width = '23',
  height = '21',
  color = '#ffff',
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5134 9.69488V10.4999C20.5123 12.3868 19.8865 14.2227 18.7293 15.734C17.5721 17.2452 15.9455 18.3508 14.0922 18.8858C12.2388 19.4208 10.258 19.3565 8.44506 18.7026C6.63216 18.0487 5.08434 16.8402 4.03243 15.2573C2.98052 13.6744 2.48089 11.8019 2.60805 9.91916C2.73521 8.03638 3.48236 6.24417 4.73805 4.80982C5.99375 3.37547 7.69071 2.37584 9.57586 1.96001C11.461 1.54418 13.4333 1.73443 15.1987 2.50238"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.5134 3.5L11.551 12.2588L8.8623 9.63375"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckCircleComponent;
