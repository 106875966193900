import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Eye from './assets/Eye';

type ButtonWithEyeType = {
  title: string;
  isOpen: boolean;
  onClick: () => void;
};

const ButtonWithEye: FC<ButtonWithEyeType> = ({ title, isOpen, onClick }) => {
  const useStyles = makeStyles((theme) => ({
    btn: {
      transition: 'background-color 0.9s ease',
      border: ' 1px solid #2B39B9',
      borderRadius: '3px',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      padding: 0,
      '&:hover': {
        filter: 'drop-shadow(0px 2px 15px rgba(122, 137, 167, 25%))',
      },
    },
  }));
  const classes = useStyles();
  return (
    <Button
      startIcon={<Eye color={isOpen ? '#fff' : '#232B95'} isOpen={isOpen} />}
      style={{
        height: '26px',
        width: '128px',
        background: `${isOpen ? '#232B95' : '#fff'}`,
        color: `${isOpen ? '#fff' : '#232B95'}`,
      }}
      size="large"
      className={classes.btn}
      variant="contained"
      color="secondary"
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

export default ButtonWithEye;
