/* eslint-disable */
import React, { FC } from 'react';
// eslint-disable-next-line import/no-cycle
import { CircleTooltip } from '../index';
import s from './QualifyingLoans.module.scss';

export interface QualifyingLoansProps {
  value: number;
  description?: string | React.ReactElement;
  label?: string;
  widgetHight?: string;
  widgetMargin?: string;
}

export interface StatsBlocksProps {
  value?: number | undefined;
  timeStamp?: string | undefined;
}

const StatsBlock: FC<StatsBlocksProps> = ({ value = 0, timeStamp }) => (
  <span>
    <h2 className={`${s.widget__value}`}>
      {value > 0 ? '+' : ''}
      {value?.toLocaleString('en')}
    </h2>
    <h5 className={`${s.widget__lastSubmission}`}>
      {`change since previous Document Date (${timeStamp})`}
    </h5>
  </span>
);
const QualifyingLoans: FC<QualifyingLoansProps> = ({
  value,
  label,
  description,
  widgetHight = '100%',
  widgetMargin = '0',
  children,
}) => (
  <div
    className={`${s.widget}`}
    style={{ height: widgetHight, marginBottom: widgetMargin }}
  >
    <div>
      <h2 className={`${s.widget__tittle}`}>
        {label}
        {!description || (
          <span className={`${s.widget__tittle__tooltip}`}>
            <CircleTooltip
              fullWidth
              width={'420px'}
              description={description}
            />
          </span>
        )}
      </h2>
    </div>
    <div>
      <div className={`row ${s.widget__vertical}`}>
        <div className="col-4">
          <h2 className={`${s.widget__currentSubmission}`}>
            {value.toLocaleString('en')}
          </h2>
        </div>
        <div className="col-8">{children}</div>
      </div>
    </div>
  </div>
);
export { QualifyingLoans, StatsBlock };
