export interface OsType {
  result: 'Mac' | 'Windows' | undefined;
}

const getOs = (): 'Windows' | 'Mac' => {
  const os = ['Windows', 'Mac']; // If in feature wee need other os , we should add OS values
  const result = os.find((v) => navigator.appVersion.indexOf(v) >= 0);
  if (result !== undefined) {
    return result as 'Windows' | 'Mac';
  }
  return 'Mac';
};

export default getOs;
