import { createTheme } from '@material-ui/core/styles';

const CUCTheme = createTheme({
  overrides: {
    MuiInput: {
      input: {
        '&::placeholder': {
          color: 'green',
        },
        color: 'green', // if you also want to change the color of the input, this is the prop you'd use
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
      color: '#000',
      backgroundColor: 'white',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#15e577',
        borderColor: '#564345',
      },
    },
  },
  palette: {
    primary: {
      main: '#7BD5A7',
      contrastText: '#FFFFFF',
    },

    secondary: {
      main: '#FFFFFF',
      contrastText: '#1A56C0',
    },
  },
});

export default CUCTheme;
