import React, { useEffect } from 'react';
import { sendGlobalData } from '../../utils/functions/sendGlobalData';

const Page404 = () => {
  useEffect(() => {
    sendGlobalData({
      errorType: 'notFoundError',
    });
  }, []);
  return <></>;
};
export { Page404 };
