/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import s from './style/Legend.module.scss';

type LegendNameType = {
  name: string;
  color: string;
};

type LegendType = {
  cuName: string | Array<LegendNameType>;
  cuNameColor?: string;
  comparisonName: string | undefined;
};

const Legend: FC<LegendType> = ({
  cuName,
  comparisonName,
  cuNameColor = '#232b95',
}) => (
  <span className={s.legend}>
    {Array.isArray(cuName) ? (
      cuName.map(({ color, name }) => (
        <div className={s.legend__text} key={name}>
          {name}
          <span
            className={s.legend__sign}
            style={{ color, backgroundColor: color }}
          />
        </div>
      ))
    ) : (
      <div className={s.legend__text}>
        {cuName}
        <span
          className={s.legend__sign}
          style={{ backgroundColor: cuNameColor }}
        />
      </div>
    )}
    {comparisonName !== 'No Comparison' && comparisonName !== cuName && (
      <div className={`${s.legend__text}`}>
        {comparisonName}
        <span className={`${s.legend__sign} ${s.legend__sign__comparison}`} />
      </div>
    )}
  </span>
);

export default Legend;
