/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useQueries } from 'react-query';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import s from './style/Dropdown.module.scss';
import { GetDropdownData, ResponseType } from '../../../../api';
import {
  setTempLocation,
  resetTags,
  setAllFilters,
} from '../../Slice/impactReportSlice';
import type { RootState } from '../../../../store/store';

interface IDropdownData {
  locationType: number;
  value: string;
}

const Dropdown: FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const results = useQueries([GetDropdownData()]);

  const DropdownData = results[0].data as ResponseType<Array<IDropdownData>>;
  const options = DropdownData as any;
  const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
  const items = useAppSelector((state) => state.impactReport);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAllFilters(options?.result));
  }, [options]);

  return (
    <div className={s.dropdown}>
      <div className={s.dropdown__btn} onClick={() => setIsActive(!isActive)}>
        {items.tempLocation.value
          ? items.tempLocation.value
          : 'Select Location Type'}
        <span className={`${s.dropdown__btn__icon} fa fa-chevron-down`} />
      </div>
      {isActive && (
        <ClickAwayListener onClickAway={() => setIsActive(false)}>
          <div className={s.dropdown__content}>
            {options?.result.map((item: any) => (
              <div
                onClick={() => {
                  dispatch(setTempLocation(item));
                  dispatch(resetTags());
                  setIsActive(false);
                }}
                className={s.dropdown__content__item}
                key={item.value}
              >
                {item.value}
              </div>
            ))}
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Dropdown;
