/* eslint-disable */
import React, { FC } from 'react';
import ReactECharts from 'echarts-for-react';

type chartData = { valueCount: number; value: string }[];
type NumberChartWidgetType = {
  cuName: string;
  width: string;
  data: any;
  title: any;
  colorPalette: any;
};

const Chart: FC<NumberChartWidgetType> = ({
  cuName,
  data,
  title,
  colorPalette,
  width,
}) => {
  const options = {
    silent: true,
    color: colorPalette,
    textStyle: {
      fontFamily: 'Open Sans',
    },
    legend: {
      show: null,
    },
    grid: {
      show: false,
      right: '0px',
      left: '-5px',
      bottom: '0px',
      height: '100px',
      width: 'auto',
      containLabel: true,
    },
    xAxis: [
      {
        axisLabel: {
          interval: 0,
          hideOverlap: false,
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          boundaryGap: true,
          lineStyle: {
            color: '#EFF2F7',
            width: 2,
          },
        },
        type: 'category',
        data: title,

        offset: 0,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#7A89A7',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        splitLine: {
          show: false,
          boundaryGap: false,
        },
        type: 'value',
      },
    ],
    series: data,
  };

  return (
    <span style={{ marginTop: '-10px' }}>
      <ReactECharts
        style={{ height: '140px', width: width }}
        option={options}
        notMerge={true}
        lazyUpdate={true}
        opts={{ renderer: 'canvas' }}
      />
    </span>
  );
};

export default Chart;
