import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { IconType } from '../../../types';

const WarringIcon: FC<IconType> = ({
  width = 24,
  height = 24,
  color = '#F4511E',
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.82002 18.0002L10.29 3.8602C10.4683 3.56631 10.7193 3.32332 11.0188 3.15469C11.3184 2.98605 11.6563 2.89746 12 2.89746C12.3438 2.89746 12.6817 2.98605 12.9812 3.15469C13.2807 3.32332 13.5318 3.56631 13.71 3.8602L22.18 18.0002C22.3547 18.3026 22.4471 18.6455 22.448 18.9947C22.449 19.3439 22.3585 19.6873 22.1856 19.9907C22.0127 20.2941 21.7633 20.547 21.4623 20.7241C21.1613 20.9012 20.8192 20.9964 20.47 21.0002H3.53002C3.18082 20.9964 2.83871 20.9012 2.53773 20.7241C2.23675 20.547 1.98738 20.2941 1.81445 19.9907C1.64151 19.6873 1.55103 19.3439 1.55201 18.9947C1.55299 18.6455 1.64539 18.3026 1.82002 18.0002Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 9V13"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 17H12.01"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

WarringIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

WarringIcon.defaultProps = {
  width: 24,
  height: 24,
  color: '#F4511E',
};

export default WarringIcon;
