import { appConfig } from '../constants';

export const IDENTITY_CONFIG = {
  authority: appConfig.indentityUrl,
  client_id: appConfig.id,
  redirect_uri: `${window.location.origin}/callback.html`,
  // automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
  response_type: 'code',
  scope: 'openid profile offline_access email cuc_scope analyzecu-api',
  client_secret: '511536EF-F270-4058-80CA-1C89C192F69A',
  post_logout_redirect_uri: `${window.location.origin}`,
};
