/* eslint-disable @typescript-eslint/indent */
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  FC,
} from 'react';

interface SubmissionStatusContextType {
  isSubmissionInProgress: boolean;
  setSubmissionInProgress: (status: boolean) => void;
}

const SubmissionStatusContext = createContext<
  SubmissionStatusContextType | undefined
>(undefined);

export const SubmissionStatusProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isSubmissionInProgress, setSubmissionInProgress] = useState(false);

  return (
    <SubmissionStatusContext.Provider
      value={{ isSubmissionInProgress, setSubmissionInProgress }}
    >
      {children}
    </SubmissionStatusContext.Provider>
  );
};

export const useSubmissionStatus = (): SubmissionStatusContextType => {
  const context = useContext(SubmissionStatusContext);
  if (!context) {
    throw new Error(
      'useSubmissionStatus must be used within a SubmissionStatusProvider'
    );
  }
  return context;
};
