/* eslint-disable */
import React, { FC } from 'react';
import s from './EmptyState.module.scss';

export interface EmptyStateProps {
  img?: boolean;
  title: string;
  text: string;
  style?: React.CSSProperties;
}

export const EmptyState: FC<EmptyStateProps> = ({
  children,
  img,
  title,
  text,
  style,
}) => (
  <div className={`${s.emptyState}`}>
    <div>
      {img && (
        <div className={`${s.emptyState__img}`}>
          <svg
            width="54"
            height="48"
            viewBox="0 0 54 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="54"
              height="48"
              rx="4"
              fill="#EBF0F8"
              fill-opacity="0.39"
            />
            <path
              d="M17 31L23 24L27.8 28.1176L37 17"
              stroke="#7A89A7"
              stroke-opacity="0.5"
              stroke-width="3"
            />
          </svg>
        </div>
      )}
      <h2 className={`${s.emptyState__title}`}>{title}</h2>
      <div className={`${s.emptyState__text}`} style={style}>
        {text}
      </div>
      <div className={`${s.emptyState__btn}`}> {children}</div>
    </div>
  </div>
);
