/* eslint-disable */
import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styles from '../NumberChartWidget/style/NumberChartWidget.module.scss';
import s from '../../style/ImpactReportPage.module.scss';

type LoadingChartWidgetType = {
  title: string;
};

const LoadingChartWidget: FC<LoadingChartWidgetType> = ({ title }) => (
  <div className={`${s.chart}`}>
    <>
      <div className="col-12" style={{ padding: 0 }}>
        <div
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ flex: 'auto' }}>
            <div
              className={`${s.loading__wrapper}`}
              style={{
                height: '50px',
              }}
            >
              <h4
                className={s.chartSubTitle}
                style={{ height: '50px', padding: '0 20px' }}
              >
                {title}
              </h4>
              <div className={`${s.loading__wrapper__legend}`}>
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </div>
            </div>

            <div
              className={`${styles.numberBlock__total} ${s.loading__wrapper__block} `}
            >
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
              <div className={`${s.loading__wrapper__block__item}`}>
                <div className={styles.numberBlock__total__label}>
                  <Skeleton width="100%" height="90px" />
                </div>
                <div
                  className={`${s.loading__sm} ${styles.numberBlock__total__value}`}
                >
                  <Skeleton width="100%" height="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  </div>
);

export default LoadingChartWidget;
