/* eslint-disable */
import React, { FC } from 'react';
import s from './style/UnionInfo.module.scss';

interface UnionInfoType {
  cuName: string;
  comparisonName?: string;
}
const UnionInfo: FC<UnionInfoType> = ({
  cuName,
  comparisonName = 'No Comparison',
}) => (
  <div className={`${s.wrapper}`}>
    <div className={`${s.wrapper__container} row`}>
      <div className={`${s.item} col-sm-6`}>
        <span className={`${s.item__subtext} ${s.item__subtext__primary}`}>
          Credit Union
        </span>
        <h1 className={`${s.item__heading}`}>{cuName}</h1>
      </div>
      <div className="col-sm-2" />
      <div className={`${s.item} col-sm-4`} style={{ paddingRight: 0 }}>
        <span className={`${s.item__subtext} ${s.item__subtext__secondary}`}>
          Comparison
        </span>
        <ul className={`${s.item__subtext__list}`}>
          <li>
            <span className={`${s.item__text}`}>{comparisonName}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default UnionInfo;
