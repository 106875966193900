import React, { FC, useEffect, memo } from 'react';
import { ToastProps } from './types';
import s from './style/Toast.module.scss';
import CloseIcon from './assets/CloseIcon';
import InfoIcon from './assets/InfoIcon';
import SuccessIcon from './assets/SuccessIcon';
import WarringIcon from './assets/WarringIcon';

const Toast: FC<ToastProps> = ({
  destroy,
  content,
  title,
  type,
  duration = 700,
  id,
}) => {
  const TOASTER_VIEW = {
    warring: { icon: <WarringIcon />, style: s.toastr__warring },
    success: { icon: <SuccessIcon />, style: s.toastr__success },
    error: { icon: <WarringIcon />, style: s.toastr__error },
    info: { icon: <InfoIcon />, style: s.toastr__warring },
  };
  useEffect(() => {
    if (!duration) return;
    const timer = setTimeout(() => {
      destroy();
    }, duration);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [destroy, duration]);

  return (
    <div className={`${s.toastr} ${TOASTER_VIEW[type].style}`} id={id}>
      <div className={`${s.toastr__icon}`}>{TOASTER_VIEW[type].icon}</div>
      <div className={`${s.toastr__content}`}>
        <div className={`${s.toastr__content__header}`}>
          <h5 className={`${s.toastr__content__header__title}`}>{title}</h5>
          <button
            name="closeBtn"
            title="closeBtn"
            type="button"
            onClick={destroy}
            className={`${s.toastr__content__header__closeBtn}`}
          >
            <CloseIcon />
          </button>
        </div>
        <p
          className={`${s.toastr__content__body}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

const shouldRerender = (prevProps: ToastProps, nextProps: ToastProps) =>
  prevProps.id === nextProps.id;

export default memo(Toast, shouldRerender);
