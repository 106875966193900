import React, { FC } from 'react';
import s from './TestStatus.module.scss';
import failed from './assets/Close.svg';
import success from './assets/Done.svg';

const TestStatus: FC<{ isPassed: boolean; isLid: boolean }> = ({
  isPassed,
  isLid,
}) => (
  <div className={`${s.statusElement}`}>
    <div className={`${s.statusElement__text}`}>
      <img src={isPassed ? success : failed} alt="status" />
      <span className={`${s.statusElement__text__status}`}>
        {isPassed
          ? 'Pre-qualified  '
          : `${isLid ? 'LID' : 'CDFI'} status not met`}
      </span>
    </div>
  </div>
);

export default TestStatus;
