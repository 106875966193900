/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import ReactECharts from 'echarts-for-react';
import { Legend } from '../../../../components';
import s from './style/RaceChart.module.scss';

type RaceType = {
  white: number;
  black: number;
  native: number;
  asian: number;
  islander: number;
  other: number;
  twoPlus: number;
  hispanic: number;
  matchPercent: number | null;
};

type Chart = {
  raceWA: null | RaceType;
  raceBISG: null | RaceType;
  raceBIFSG: null | RaceType;
};

type RaceChartType = {
  cuName: string;
  comparisonName: string;
  selected: string;
  chartData: Chart;
  comparisonChartData: Chart;
  isComparison: boolean;
};

const methodology = {
  raceBIFSG: 'BIFSG Methodology',
  raceBISG: 'BISG Methodology (Fair Lending)',
  raceWA: 'Weighted Average of Census Block Groups',
};

const ExportRaceChart: FC<RaceChartType> = ({
  cuName,
  comparisonName,
  chartData,
  comparisonChartData,
  selected,
  isComparison,
}) => {
  const colorPalette = ['#232B95', '#52B36B'];

  function getChartData(select: string, objectChartData: any) {
    const raceData = objectChartData[select];
    let result = [];

    if (select === 'raceWA') {
      result =
        raceData === null
          ? [
              {
                name: 'Asian/Pacific Islander',
                value: 'N/A',
                valueAsian: 'N/A',
                valueIslander: 'N/A',
              },
              {
                name: 'Black/African-American',
                value: 'N/A',
              },
              {
                name: 'Hispanic/Latino',
                value: 'N/A',
              },
              {
                name: 'Multiple Races/Another Race',
                value: 'N/A',
                valueTwoPlus: 'N/A',
                valueOther: 'N/A',
              },
              {
                name: 'Native American',
                value: 'N/A',
              },
              {
                name: 'White',
                value: 'N/A',
              },
            ]
          : [
              {
                name: 'Asian/Pacific Islander',
                value: (
                  Math.round((raceData?.asian + raceData?.islander) * 100) / 100
                ).toFixed(2),
                valueAsian: (Math.round(raceData?.asian * 100) / 100).toFixed(
                  2
                ),
                valueIslander: (
                  Math.round(raceData?.islander * 100) / 100
                ).toFixed(2),
              },
              {
                name: 'Black/African-American',
                value: (Math.round(raceData?.black * 100) / 100).toFixed(2),
              },
              {
                name: 'Hispanic/Latino',
                value: (Math.round(raceData?.hispanic * 100) / 100).toFixed(2),
              },
              {
                name: 'Multiple Races/Another Race',
                value: (
                  Math.round((raceData?.other + raceData?.twoPlus) * 100) / 100
                ).toFixed(2),
                valueTwoPlus: (
                  Math.round(raceData?.twoPlus * 100) / 100
                ).toFixed(2),
                valueOther: (Math.round(raceData?.other * 100) / 100).toFixed(
                  2
                ),
              },
              {
                name: 'Native American',
                value: (Math.round(raceData?.native * 100) / 100).toFixed(2),
              },
              {
                name: 'White',
                value: (Math.round(raceData?.white * 100) / 100).toFixed(2),
              },
            ];
    } else {
      result =
        raceData === null
          ? [
              {
                name: 'Asian/Pacific Islander',
                value: 'N/A',
              },
              {
                name: 'Black/African-American',
                value: 'N/A',
              },
              {
                name: 'Hispanic/Latino',
                value: 'N/A',
              },

              {
                name: 'Multiple Races/Another Race',
                value: 'N/A',
              },
              {
                name: 'Native American',
                value: 'N/A',
              },
              {
                name: 'White',
                value: 'N/A',
              },
            ]
          : [
              {
                name: 'Asian/Pacific Islander',
                value: (
                  Math.round((raceData?.asian + raceData?.islander) * 100) / 100
                ).toFixed(2),
              },
              {
                name: 'Black/African-American',
                value: (Math.round(raceData?.black * 100) / 100).toFixed(2),
              },
              {
                name: 'Hispanic/Latino',
                value: (Math.round(raceData?.hispanic * 100) / 100).toFixed(2),
              },

              {
                name: 'Multiple Races/Another Race',
                value: (
                  Math.round((raceData?.other + raceData?.twoPlus) * 100) / 100
                ).toFixed(2),
              },
              {
                name: 'Native American',
                value: (Math.round(raceData?.native * 100) / 100).toFixed(2),
              },
              {
                name: 'White',
                value: (Math.round(raceData?.white * 100) / 100).toFixed(2),
              },
            ];
    }

    return result;
  }
  const options = {
    color: colorPalette,
    textStyle: {
      fontFamily: 'Open Sans',
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#282828',
      borderColor: '#282828',
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        color: '#FFFFFF',
      },
      padding: 16,
      formatter(params: any) {
        const colorSpan = (color: any) =>
          `<span style="display:inline-block;margin-right:5px;border:1px #FFFFFF solid;border-radius:10px;width:9px;height:9px;background-color:${color}"></span>`;
        let rez = `<p style="font-size:18px;font-family: Open Sans;font-weight: 700;">${params[0].axisValue}</p>`;
        params.forEach((item: any) => {
          if (item.data.name === 'Asian/Pacific Islander') {
            let xx;
            if (selected === 'raceWA') {
              xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item.color
              )} ${
                item.seriesName
              }</p><div style="margin:2px;padding:0;"><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Asian: ${
                item.data?.valueAsian
              }%</li><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Pacific Islander: ${
                item.data?.valueIslander
              }%</li></div>`;
            } else {
              xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item.color
              )} ${
                item.seriesName
              }</p><div style="margin:2px;padding:0px"><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Asian or Pacific Islander: ${
                item.data?.value
              }%</li></div>`;
            }
            rez += xx;
          } else if (item.data.name === 'Multiple Races/Another Race') {
            let xx;
            if (selected === 'raceWA') {
              xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item.color
              )} ${
                item.seriesName
              }</p><div style="margin:2px;padding:0;"><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Other: ${
                item.data?.valueOther
              }%</li><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Two or more: ${
                item.data?.valueTwoPlus
              }%</li></div>`;
            } else {
              xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item?.color
              )} ${
                item?.seriesName
              }</p><div style="margin:2px;padding:0px"><li style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">Other or Two or more: ${
                item.data?.value
              }%</li></div>`;
            }
            rez += xx;
          } else {
            const xx =
              `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
                item.color
              )} ${item.seriesName}: ${item.data.value}%` + '</p>';
            rez += xx;
          }
        });

        return rez;
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      rich: {
        a: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '12px',
          color: '#282828',
        },
        b: {
          padding: [0, 0, 6, 0],
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '26px',
          align: 'center',
          color: '#282828',
        },
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          borderColor: 'black',
          borderWidth: 5,
          opacity: 0.5,
        },
      },
    },
    title: {
      z: 10,
      text: null,
      left: 25,
      padding: 0,
      textStyle: {
        fontSize: 16,
        fontWeight: 600,
      },
    },
    legend: {
      show: null,
    },
    grid: {
      show: false,
      left:
        chartData[selected] == null && comparisonChartData.raceWA == null
          ? '0'
          : '-20',
      right: '0',
      bottom: '6%',
      top: '12%',
      containLabel: true,
    },
    xAxis: [
      {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          boundaryGap: true,
          lineStyle: {
            color: '#EFF2F7',
            width: 2,
          },
        },
        type: 'category',
        data: [
          {
            value: 'Asian/Pacific \n\n Islander',
            textStyle: {
              fontSize: 8,
            },
          },
          {
            value: 'Black/African-\n\nAmerican',
            textStyle: {
              fontSize: 8,
            },
          },
          {
            value: 'Hispanic/\n\nLatino',
            textStyle: {
              fontSize: 8,
            },
          },
          {
            value: 'Multiple Races/\n\nAnother Race',
            textStyle: {
              fontSize: 8,
            },
          },
          {
            value: 'Native\n\n American',
            textStyle: {
              fontSize: 8,
            },
          },
          {
            value: 'White',
            textStyle: {
              fontSize: 8,
            },
          },
        ],
        axisLabel: {
          interval: 0,
        },
        offset: 8,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#7A89A7',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        splitLine: {
          show: false,
          boundaryGap: false,
        },
        type: 'value',
      },
    ],
    series: [
      {
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            color: 'rgba(33, 3, 3, 1)',
          },
        },
        barWidth: !isComparison && '35%',
        name: cuName,
        type: 'bar',
        label: {
          fontSize: 8,
          show: true,
          color: 'inherit',
          position: 'top',
          valueAnimation: true,
          formatter: '{@score}%',
        },
        data: getChartData(selected, chartData),
      },
      isComparison && {
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            color: 'rgba(33, 3, 3, 1)',
          },
        },
        name: comparisonName,
        type: 'bar',
        label: {
          fontSize: 8,
          show: true,
          color: 'inherit',
          position: 'top',
          valueAnimation: true,
          formatter: '{@score}%',
        },
        data: getChartData('raceWA', comparisonChartData),
      },
    ],
  };
  return (
    <>
      <div className={`${s.title} ${s.print}`}>
        <div>
          <span style={{ fontSize: '8px' }}>
            Race & Hispanic
            <span className={s.margin} />
          </span>
          {selected === 'raceWA' || (
            <span
              className={s.subTitle}
              style={{
                fontSize: '6px',
                color:
                  chartData[selected]?.matchPercent <= 10
                    ? '#ED5050'
                    : '#232B95',
              }}
            >
              {Math.round(
                (chartData[selected]?.matchPercent * 100) / 100
              ).toFixed(0)}
              % Match Rate <span className={s.margin} />
              <span className={s.margin} />
            </span>
          )}
          <span
            style={{ fontSize: '6px', color: '#7A89A7' }}
            className={s.subTitle}
          >
            Methodology:{' '}
            <span style={{ color: '#232b95', fontSize: '6px' }}>
              {methodology[selected as any]}
            </span>
          </span>
        </div>
        <Legend cuName={cuName} comparisonName={comparisonName} />
      </div>

      <div style={{ marginTop: '12px', height: '120px', width: '756px' }}>
        <ReactECharts
          style={{ height: '120px', width: '756px' }}
          option={options}
          notMerge={true}
          lazyUpdate={true}
          opts={{ renderer: 'canvas' }}
        />
      </div>
    </>
  );
};

export default ExportRaceChart;
