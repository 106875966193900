import { UseQueryOptions } from 'react-query';
import httpClient from '../../../helpers/http';

export const GetEnabledTotalMembers = ({
  isRefetch,
  locationIds,
}: {
  locationIds: Array<number>;
  isRefetch: boolean;
}): UseQueryOptions => ({
  queryKey: [
    `Impact-report-page-enabled-total-members-${locationIds}-${
      isRefetch ? '-refetched' : ''
    }`,
  ],
  queryFn: () =>
    httpClient.get(
      `/api/impactreporting/TotalBenefitsStatus?${locationIds
        .map(
          (value: number, index: number, array: Array<number>) =>
            `locationIds=${value}${index === array.length - 1 ? '' : '&'}`
        )
        .join('')}`
    ),
  refetchInterval: isRefetch ? 10000 : false,
  cacheTime: 0,
  staleTime: 0,
});

export const GetCombinedStatsData = ({
  locationIds,
  combinedStatus,
  isEnabled,
}: {
  locationIds: Array<number>;
  combinedStatus: 0 | 1 | 2;
  isEnabled: boolean;
}): UseQueryOptions => ({
  queryKey: [
    `Impact-report-combined-stats-data-${locationIds}-${combinedStatus}-${isEnabled}`,
  ],
  queryFn: () =>
    httpClient.get(
      `/api/impactreporting/combinedstats?${locationIds
        .map(
          (value: number, index: number, array: Array<number>) =>
            `locationIds=${value}${index === array.length - 1 ? '' : '&'}`
        )
        .join('')}`
    ),
  enabled: isEnabled,
});

export const GetCreditScoreTiers = ({
  locationIds,
}: {
  locationIds: Array<number>;
}): UseQueryOptions => ({
  queryKey: [`Impact-report-page-creditscoretiers-${locationIds}`],
  queryFn: () =>
    httpClient.get(
      `/api/impactreporting/creditscoretiers?${locationIds
        .map(
          (value: number, index: number, array: Array<number>) =>
            `locationIds=${value}${index === array.length - 1 ? '' : '&'}`
        )
        .join('')}`
    ),
});

export const GetDropdownData = (): UseQueryOptions => ({
  queryKey: ['Impact-report-dropdown-data'],
  queryFn: () =>
    httpClient.get('/api/impactreporting/locationtypeautocomplete'),
});

export const GetSavedFiltersData = (): UseQueryOptions => ({
  queryKey: ['Impact-report-saved-filters-data'],
  queryFn: () => httpClient.get('/api/impactreporting/locationfilters'),
});

export const GetLocationAutocomplete = ({
  searchTerm,
  subType,
}: {
  searchTerm: string;
  subType: number;
}): UseQueryOptions => ({
  queryKey: ['Impact-report-page-location-autocomplete'],
  queryFn: () =>
    httpClient.get(
      `/api/impactreporting/locationtypeautocomplete?searchTerm=${searchTerm}&subType=${subType}`
    ),
});

export const GetAlertData = ({
  locationIds,
}: {
  locationIds: Array<number>;
}): UseQueryOptions => ({
  queryKey: [`Impact-report-page-alert-data-${locationIds}`],
  queryFn: () =>
    httpClient.get(
      `/api/impactreporting/alertdataupload?${locationIds
        .map(
          (value: number, index: number, array: Array<number>) =>
            `locationIds=${value}${index === array.length - 1 ? '' : '&'}`
        )
        .join('')}`
    ),
});

export const GetMemberLoansPopulationData = ({
  locationIds,
}: {
  locationIds: Array<number>;
}): UseQueryOptions => ({
  queryKey: [`Impact-report-page-member-loans-population-${locationIds}`],
  queryFn: () =>
    httpClient.get(
      `/api/impactreporting/locationitemsrecent?${locationIds
        .map(
          (value: number, index: number, array: Array<number>) =>
            `locationIds=${value}${index === array.length - 1 ? '' : '&'}`
        )
        .join('')}`
    ),
});

export const GetRecentFilters = (): UseQueryOptions => ({
  queryKey: ['Impact-report-page-get-recent-filters'],
  queryFn: () => httpClient.get('/api/impactreporting/locationitemsrecent'),
});
