/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-indent */
import React, { FC, memo, useCallback } from 'react';
import * as Select from '@radix-ui/react-select';
import s from './style/Select.module.scss';
import Arrow from '../../../DataManagementPage/widgets/assets/Arrow';

const methodology = {
  raceBIFSG: 'BIFSG Methodology',
  raceBISG: 'BISG Methodology (Fair Lending)',
  raceWA: 'Weighted Average of Census Block Groups',
};

const methodologyInfo = {
  raceBIFSG:
    'The Bayesian Improved First Name Surname Geocoding (BIFSG) methodology estimates are based on the race and ethnicity distributions of the census block groups in which a consumer lives, their last name, and their first name.',
  raceBISG:
    'The Bayesian Improved Surname Geocoding (BISG) methodology estimates are based on the race and ethnicity distributions of both the census block groups in which a consumer lives and their last name. This methodology was first developed by the Rand Corporation and is used by the Consumer Financial Protection Bureau (CFPB) to assess compliance with fair lending laws.',
  raceWA:
    'Estimates are a weighted average of the race and ethnicity distribution of the census block groups in which consumers reside.',
};

interface SelectItemProps extends Select.SelectItemProps {
  title: string | JSX.Element[];
  children: React.ReactNode;
}

const SelectItem = memo(
  React.forwardRef<HTMLDivElement, SelectItemProps>(
    ({ title, children, ...props }, forwardedRef) => (
      <Select.Item
        className={s.select__content__item}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>
          <>
            <div className={s.select__content__item__title}>
              {typeof title === 'string' ? title : title}
            </div>
            <div className={s.select__content__item__margin} />
            <div className={s.select__content__item__text}>{children}</div>
          </>
        </Select.ItemText>
      </Select.Item>
    )
  )
);

interface SelectComponentProps {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

export const SelectComponent: FC<SelectComponentProps> = ({
  selected,
  setSelected,
}) => {
  const selectItemRef = useCallback((node) => node, []);

  return (
    <Select.Root value={selected} onValueChange={setSelected} name={selected}>
      <Select.Trigger className={s.select} aria-label="Methodology">
        <Select.Value>
          <>
            <span className={`${s.select__value} ${s.select__value__grey}`}>
              Methodology:{' '}
            </span>
            <span className={`${s.select__value} ${s.select__value__blue}`}>
              {methodology[selected]}
            </span>
          </>
        </Select.Value>
        <Select.Icon className={s.select__icon}>
          <Arrow color="#7A89A7" />
        </Select.Icon>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          alignOffset={-20}
          sticky="always"
          hideWhenDetached={!false}
          position="popper"
        >
          <Select.Viewport className={s.select__viewport}>
            <Select.Group className={s.select__content}>
              <SelectItem
                value="raceBIFSG"
                ref={selectItemRef}
                title={[
                  <React.Fragment key="methodology">
                    BIFSG Methodology{' '}
                  </React.Fragment>,
                  <span
                    key="recommended"
                    className={s.select__content__item__title__subTitle}
                  >
                    (Recommended)
                  </span>,
                ]}
              >
                {methodologyInfo.raceBIFSG}
              </SelectItem>
              <SelectItem
                value="raceBISG"
                ref={selectItemRef}
                title="BISG Methodology (Fair Lending)"
              >
                {methodologyInfo.raceBISG}
              </SelectItem>
              <SelectItem
                value="raceWA"
                ref={selectItemRef}
                title="Weighted Average of Census Block Groups"
              >
                {methodologyInfo.raceWA}
              </SelectItem>
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
