import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { HashRouter } from 'react-router-dom';

import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ModalService from '@ebay/nice-modal-react';
import { Provider } from 'react-redux';
import CUCTheme from './styles/theme';
import App from './app/App';
import { store } from './app/store/store';
import { ErrorBoundary } from './app/utils/ErrorBoundary';
import queryClient from './QueryClient';
import { SubmissionStatusProvider } from './app/containers/DataManagementPage/SubmissionStatusContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './styles/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={CUCTheme}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ErrorBoundary>
            <HashRouter>
              <SubmissionStatusProvider>
                <ModalService.Provider>
                  <App />
                </ModalService.Provider>
              </SubmissionStatusProvider>
            </HashRouter>
          </ErrorBoundary>
        </Provider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('analyzecu-app')
);
