/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import styles from '../../style/ImpactReportPage.module.scss';
import s from './style/TotalBenefitsWidgetType.module.scss';
import { CircleTooltip } from '../../../../components';
import abbreviateNumber from '../../../../utils/functions/abbreviateNumber';
import LoadingTotalBenefitsWidget from './LoadingTotalBenefitsWidget';

export type EnabledTotalMembersType = {
  isLoanMapped: boolean;
  isShareMapped: boolean;
  isPfcMapped: boolean;
  calcStatus: 1 | 2 | 0;
};
// 1 -in progress
// 2- completed
// 0 -no completed

interface TotalBenefitsWidgetType {
  totalBenefitsToMembers: number;
  benefitPerMember: number;
  title: string;
  isLoading: boolean;
  enabledTotalMembersData: EnabledTotalMembersType;
}

const TotalBenefitsWidget: FC<TotalBenefitsWidgetType> = ({
  benefitPerMember,
  title,
  isLoading,
  totalBenefitsToMembers,
  enabledTotalMembersData,
}) => {
  const useStyles = makeStyles(() => ({
    linkBtn: {
      padding: '0',
      width: '100%',
      maxWidth: '180px',
      height: '40px',
      border: '1px solid #2B39B9',
      borderRadius: '3px',
      '&:hover': {
        borderColor: '#564345',
        boxShadow: '0px 2px 15px rgb(0 0 0 / 35%)',
        borderRadius: '4px',
        background: '#EFF2F7',
      },
    },
  }));
  const classes = useStyles();
  const isEnabled =
    !enabledTotalMembersData.isLoanMapped ||
    !enabledTotalMembersData.isPfcMapped ||
    !enabledTotalMembersData.isShareMapped;
  return isLoading ? (
    <LoadingTotalBenefitsWidget />
  ) : enabledTotalMembersData.calcStatus === 2 ? (
    <div className={`${s.benefits}`}>
      <div className="col-sm-12">
        <h4 className={`${s.benefits__title}`}>
          {title}
          <span className={`${styles.chartSubTitle__margin}`}>
            <CircleTooltip
              description={
                <div className={`${styles.tooltip__text}`}>
                  Consumers includes credit union members, as well as
                  non-members with the sum of (1) the dollar amount that credit
                  union consumers save by having loans with interest rates that
                  are lower than what borrowers with their credit histories
                  would pay at other lenders and (2) the extra dollar amounts
                  that credit union consumers receive by having deposits with
                  interest rates that are higher than they would be at banks,
                  each during the last available calendar year. Note that actual
                  benefits are larger than our estimates, since we do not
                  include, for instance, the benefits from lower fees on other
                  financial products.
                </div>
              }
            />
          </span>
        </h4>
        <div className="row" style={{ height: '90%', alignContent: 'center' }}>
          <div className={`${s.benefits__mainValue} col-6`}>
            {`$${abbreviateNumber(totalBenefitsToMembers)}`}
          </div>
          <div className={`${s.benefits__block} col-5`}>
            <div className={s.benefits__member__value}>
              {`$${abbreviateNumber(benefitPerMember)}`}
            </div>
            <div className={s.benefits__member__info}>
              Annual Benefit per Consumer
              <span style={{ marginLeft: '4px' }}>
                <CircleTooltip
                  description={
                    <div className={`${styles.tooltip__text}`}>
                      Annual benefits are only computed for consumers for which
                      we have complete data
                    </div>
                  }
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : isEnabled ? (
    <div className={`${s.noData}`}>
      <h4 className={`${s.noData__title}`}>
        {title}
        <span className={`${styles.chartSubTitle__margin}`}>
          <CircleTooltip
            description={
              <div className={`${styles.tooltip__text}`}>
                Consumers includes credit union members, as well as non-members
                with the sum of (1) the dollar amount that credit union
                consumers save by having loans with interest rates that are
                lower than what borrowers with their credit histories would pay
                at other lenders and (2) the extra dollar amounts that credit
                union consumers receive by having deposits with interest rates
                that are higher than they would be at banks, each during the
                last available calendar year. Note that actual benefits are
                larger than our estimates, since we do not include, for
                instance, the benefits from lower fees on other financial
                products.
              </div>
            }
          />
        </span>
      </h4>
      <>
        <div className={`${s.noData__body}`}>
          <div
            className={`${s.noData__body__text}`}
            style={{
              width:
                !enabledTotalMembersData.isLoanMapped &&
                !enabledTotalMembersData.isPfcMapped &&
                !enabledTotalMembersData.isShareMapped
                  ? '80%'
                  : '100%',
            }}
          >
            This information cannot be calculated unless you provide
            {` ${
              enabledTotalMembersData.isLoanMapped
                ? ''
                : `Loan${
                    enabledTotalMembersData.isShareMapped ||
                    enabledTotalMembersData.isPfcMapped
                      ? ''
                      : ','
                  }`
            }
         ${
           enabledTotalMembersData.isShareMapped
             ? ''
             : `${enabledTotalMembersData.isPfcMapped ? 'and Share' : 'Share'}`
         }
         ${
           enabledTotalMembersData.isPfcMapped
             ? ''
             : `${
                 enabledTotalMembersData.isLoanMapped &&
                 enabledTotalMembersData.isShareMapped
                   ? 'Payment Frequency Code'
                   : 'and Payment Frequency Code'
               }`
         }
         Mapping Data.`}
          </div>
        </div>
        <div className={`${s.noData__body__buttons}`}>
          {enabledTotalMembersData.isLoanMapped || (
            <Button
              size="large"
              className={classes.linkBtn}
              variant="contained"
              color="secondary"
            >
              <Link
                to={{
                  pathname: '/',
                  search: '?activeTab=loans-mapping',
                }}
              >
                Go to Loan Mapping
              </Link>
            </Button>
          )}
          {enabledTotalMembersData.isShareMapped || (
            <Button
              size="large"
              className={classes.linkBtn}
              variant="contained"
              color="secondary"
            >
              <Link
                to={{
                  pathname: '/',
                  search: '?activeTab=shares-mapping',
                }}
              >
                Go to Share Mapping
              </Link>
            </Button>
          )}
          {enabledTotalMembersData.isPfcMapped || (
            <Button
              size="large"
              className={classes.linkBtn}
              style={{ maxWidth: '320px' }}
              variant="contained"
              color="secondary"
            >
              <Link
                to={{
                  pathname: '/',
                  search: '?activeTab=code-mapping',
                }}
              >
                Go to Payment Frequency Code Mapping
              </Link>
            </Button>
          )}
        </div>
      </>
    </div>
  ) : (
    <div className={`${s.noData}`}>
      <h4
        className={`${s.noData__title}`}
        style={{
          textAlign: 'center',
        }}
      >
        {title}
        <span className={`${styles.chartSubTitle__margin}`}>
          <CircleTooltip
            description={
              <div className={`${styles.tooltip__text}`}>
                Consumers includes credit union members, as well as non-members
                with the sum of (1) the dollar amount that credit union
                consumers save by having loans with interest rates that are
                lower than what borrowers with their credit histories would pay
                at other lenders and (2) the extra dollar amounts that credit
                union consumers receive by having deposits with interest rates
                that are higher than they would be at banks, each during the
                last available calendar year. Note that actual benefits are
                larger than our estimates, since we do not include, for
                instance, the benefits from lower fees on other financial
                products.
              </div>
            }
          />
        </span>
      </h4>

      <div className={`${s.noData__body}`}>
        <div
          className={`${s.noData__body__text}`}
          style={{ marginTop: '4px', fontSize: '14px', fontWeight: 600 }}
        >
          The calculation is in progress. This information will be shown soon.
        </div>
      </div>
    </div>
  );
};

export default TotalBenefitsWidget;
