import React, { FC } from 'react';
import PropTypes from 'prop-types';
import s from './TestStatus.module.scss';
import success from './assets/success.svg';
import failed from './assets/failed.svg';

interface TestStatusProps {
  isPassed: boolean;
  isLid: boolean;
  customText?: string;
}

const TestStatus: FC<TestStatusProps> = ({ isPassed, isLid, customText }) => (
  <div
    className={`${s.statusElement}
    ${isPassed ? s.statusElement__is_true : s.statusElement__is_false}`}
  >
    <div className={`${s.statusElement__text}`}>
      <img src={isPassed ? success : failed} alt="status" />
      <span className={`${s.statusElement__text__status}`}>
        {customText ||
          (isPassed
            ? 'Pre-qualified'
            : `${isLid ? 'LID' : 'CDFI'} status not met`)}
      </span>
    </div>
  </div>
);

TestStatus.propTypes = {
  isPassed: PropTypes.bool.isRequired,
  isLid: PropTypes.bool.isRequired,
  customText: PropTypes.string,
};

TestStatus.defaultProps = {
  customText: undefined,
};

export default TestStatus;
