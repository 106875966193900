import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { CSSTransition } from 'react-transition-group';

interface DataLoaderType {
  isLoading: boolean;
}

const DataLoader: FC<DataLoaderType> = ({ isLoading, children }) => (
  <>
    <CSSTransition
      in={isLoading}
      classNames="my-node"
      timeout={2}
      unmountOnExit
    >
      <Skeleton variant="rect" width="100%" height="100%" />
    </CSSTransition>
    <CSSTransition
      in={!isLoading}
      classNames="my-node"
      timeout={1000}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  </>
);

export default DataLoader;
