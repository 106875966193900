import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { IconType } from '../../../types';

const CloseIcon: FC<IconType> = ({
  width = 14,
  height = 14,
  color = '#282828',
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1L1 13"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 1L13 13"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

CloseIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CloseIcon.defaultProps = {
  width: 14,
  height: 14,
  color: '#282828',
};

export default CloseIcon;
