/* eslint-disable */
import React, { FC } from 'react';
import ReactECharts from 'echarts-for-react';

interface DataItem {
  name: String;
  value: any[];
}

interface DataSeries {
  data: DataItem[];
}

export interface BarChartProps {
  data: object;
  title: string;
  legendTitle: any[];
  legendData: any[];
  showLegend?: boolean;
  isEmpty: boolean;
  styleProperty?: any;
  bottomMargin?: string;
  colorPalette?: string[];
  style?: any;
}

const BarChart: FC<BarChartProps> = ({
  showLegend = true,
  bottomMargin = '5%',
  style = { marginTop: '4px', height: '100%', width: '100%' },
  colorPalette = ['#232B95', '#52B36B'],
  data,
  title,
  legendTitle,
  legendData,
  isEmpty,
  styleProperty,
}) => {
  const options = {
    silent: true,
    color: colorPalette,
    textStyle: {
      fontFamily: 'Open Sans',
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#282828',
      borderColor: '#282828',
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        color: '#FFFFFF',
      },
      padding: 16,
      formatter: function (params: any) {
        var colorSpan = (color: any) =>
          '<span style="display:inline-block;margin-right:5px;border:1px #FFFFFF solid;border-radius:10px;width:9px;height:9px;background-color:' +
          color +
          '"></span>';
        let rez =
          '<p style="font-size:18px;font-family: Open Sans;font-weight: 700;">' +
          params[0].axisValue +
          '</p>';
        params.forEach((item: any) => {
          var xx =
            '<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">' +
            colorSpan(item.color) +
            ' ' +
            item.seriesName +
            ': ' +
            item.data +
            '%' +
            '</p>';
          rez += xx;
        });

        return rez;
      },
      extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
      rich: {
        a: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '12px',
          color: '#282828',
        },
        b: {
          padding: [0, 0, 6, 0],
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '26px',
          align: 'center',
          color: '#282828',
        },
      },
      axisPointer: {
        // 'line' | 'shadow'
        type: 'shadow',
        shadowStyle: {
          borderColor: 'black',
          borderWidth: 5,
          opacity: 0.5,
        },
      },
    },
    title: {
      z: 10,
      text: showLegend ? title : null,
      left: isEmpty ? 25 : 0,
      padding: 0,
      textStyle: {
        fontSize: 16,
        fontWeight: 600,
      },
    },
    legend: {
      //Top tittles
      show: showLegend,
      data: legendTitle,
      itemHeight: 7,
      itemWidth: 18,
      right: '0',
      z: 20,
      width: '13%',
      align: 'right',
      reversed: true,
      verticalAlign: 'top',
      floating: true,
      padding: 4,
      symbolPadding: -3,
      itemDistance: 10,
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        color: '#282828',
        fontStyle: 'normal',
        fontSize: '12px',
      },
    },
    grid: {
      show: false,
      left: '0',
      right: '0',
      bottom: bottomMargin,
      containLabel: true,
    },
    xAxis: [
      {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          boundaryGap: true,
          lineStyle: {
            color: '#EFF2F7',
            width: 2,
          },
        },
        type: 'category',
        data: legendData,
        axisLabel: {
          interval: 0,
          // rotate: 30 //If the label names are too long you can manage this by rotating the label.
        },
        offset: 8,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#7A89A7',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        splitLine: {
          show: false,
          boundaryGap: false,
        },
        type: 'value',
      },
    ],
    series: data,
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        ...styleProperty,
      }}
    >
      <ReactECharts
        option={options}
        notMerge={true}
        lazyUpdate={true}
        style={style}
        opts={{ renderer: 'canvas' }}
      />
    </div>
  );
};
export default BarChart;
