/* eslint-disable */
import React, { ReactNode, FC } from 'react';
import s from './BreadcrumbsComponent.module.scss';
import TestStatus from './TestStatus';
import { CircleTooltip } from '../CircleTooltip';

type BreadcrumbsProps = {
  titleName?: string;
  children?: ReactNode | null;
  isLID: boolean;
  isTestPassed?: boolean;
  crumbs: Array<BreadcrumbsArrayConfiguration>;
  wrapperStyle?: React.CSSProperties;
  tooltipText?: string;
  showTooltip?: boolean;
  customText?: string;
};

export type BreadcrumbsArrayConfiguration = {
  name: string;
  path: string;
};

const defaultTooltipText =
  "The CDFI Pre-Qualification Report provides very high-quality estimates of credit unions' ability to meet two key components of the requirements to qualify as CDFI, with 60% thresholds for both the number and dollar amount of loans. As a pre-qualification report, it provides credit unions with a high-quality, but preliminary, assessment of whether they are relatively close or far from meeting two key requirements. Having levels above 60% in the pre-qualification report is not a guarantee or definitive evidence that a credit union will qualify as CDFI. To become CDFI certified, credit unions should verify whether they meet the full set of requirements and carry out a full verification of their number of loan and dollar amount data.";

const BreadcrumbsComponent: FC<BreadcrumbsProps> = ({
  isTestPassed,
  isLID,
  titleName,
  crumbs,
  wrapperStyle,
  tooltipText,
  showTooltip = true,
  customText,
}) => {
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className={`row ${s.breadcrumb}`}>
      <div className="col-sm-12 p-0" style={wrapperStyle}>
        <ol className={`${s.breadcrumb__source}`}>
          {crumbs.map(
            ({ name, path }: BreadcrumbsArrayConfiguration, key: number) =>
              key + 1 === crumbs.length ? (
                <li
                  key={name}
                  className={`breadcrumb-item ${s.breadcrumb__source__item} `}
                >
                  <strong className={`${s.breadcrumb__source__item__active} `}>
                    {name}
                  </strong>
                </li>
              ) : (
                <li
                  key={name}
                  className={`breadcrumb-item ${s.breadcrumb__source__item} `}
                >
                  <span>{name}</span>
                </li>
              )
          )}
        </ol>
        <div className={`${s.breadcrumb__block}`}>
          <div className={`${s.breadcrumb__block__element}`}>
            <h2 className={`${s.breadcrumb__block__title}`}>{titleName}</h2>
            {isTestPassed !== undefined && !isLID && showTooltip && (
              <div style={{ display: 'flex', marginLeft: '8px' }}>
                <CircleTooltip
                  description={tooltipText || defaultTooltipText}
                  fullWidth
                  width="370px"
                  iconStyle={{ width: '14px', height: '14px' }}
                />
              </div>
            )}
          </div>
          <div className={`${s.breadcrumb__block__element}`}>
            {isTestPassed !== undefined && (
              <TestStatus
                isPassed={isTestPassed}
                isLid={isLID}
                customText={customText}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbsComponent;
