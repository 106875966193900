/* eslint-disable */
import React, { FC } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, makeStyles } from '@material-ui/core/styles';
import tooltip from './tooltip.svg';
import errorTooltip from './errorTooltip.svg';

interface CircleTooltipType {
  description: string | React.ReactElement;
  position?: 'top' | 'left' | 'right' | 'bottom';
  fullWidth?: boolean;
  isError?: boolean;
  width?: string;
  iconStyle?: React.CSSProperties;
}

const CircleTooltip: FC<CircleTooltipType> = ({
  description,
  isError = false,
  position = 'top',
  fullWidth = false,
  width = '350px',
  iconStyle,
}) => {
  const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: '#282828',
    },
    tooltip: {
      fontSize: '12px',
      maxWidth: `${fullWidth ? width : '320px'}`,
      padding: '16px',
      backgroundColor: '#282828',
      color: 'white',
    },
  }));
  const classes = useStylesBootstrap();
  return (
    <Tooltip
      classes={classes}
      placement={position}
      title={description}
      interactive
      arrow
    >
      <img
        src={isError ? errorTooltip : tooltip}
        alt="ToolTip"
        style={iconStyle}
      />
    </Tooltip>
  );
};

export { CircleTooltip };
