import React, { FC, ChangeEvent } from 'react';
import { Tabs, Tab, makeStyles, Theme } from '@material-ui/core';

interface TabInfo {
  label: string;
}

interface CenteredTabsProps {
  tabs: TabInfo[];
  handleChange: (event: ChangeEvent<{}>, newValue: number) => void;
  value: number;
  // eslint-disable-next-line react/require-default-props
  styleType?: 'primary' | 'secondary';
}

const useStyles = makeStyles((theme: Theme) => {
  const commonStyles = {
    textTransform: 'none',
    minWidth: 72,
    opacity: 1,
    background: 'none',
    fontSize: '16px',
    lineHeight: '22px',
    fontFamily: 'Open Sans',
  };

  const primaryStyles = {
    color: '#282828',
    fontWeight: 600,
    marginRight: theme.spacing(4),
    borderRadius: '4px',
    background: 'none',
    '&:hover': {
      color: '#232B95',
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium,
      boxShadow: '0px 2px 15px rgb(0 0 0 / 5%)',
      borderRadius: '4px',
      background: 'none',
    },
    '&$antTabSelected': {
      color: '#232B95',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#232B95',
    },
  };

  const secondaryStyles = {
    alignText: 'left',
    color: '#282828',
    padding: '4px',
    fontWeight: 600,
    marginRight: '24px',
    '&:hover': {
      color: '#232B95',
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium,
      boxShadow: '0px 2px 15px rgb(0 0 0 / 5%)',
      borderRadius: '4px',
      background: 'none',
    },
    '&$antTabSelected': {
      color: '#232B95',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#232B95',
    },
  };

  return {
    root: {
      flexGrow: 1,
    },
    primaryTabs: {
      borderBottom: '2px solid #e8e8e8',
    },
    secondaryTabs: {
      borderBottom: 'none',
    },
    primaryTab: {
      ...commonStyles,
      ...primaryStyles,
    },
    secondaryTab: {
      ...commonStyles,
      ...secondaryStyles,
    },
    antTabSelected: {},
    indicator: {
      backgroundColor: '#232B95',
    },
  };
});

const CenteredTabs: FC<CenteredTabsProps> = ({
  children,
  tabs,
  value,
  handleChange,
  styleType = 'primary',
}) => {
  const classes = useStyles();

  const getAntTabsClass = () =>
    styleType === 'primary' ? classes.primaryTabs : classes.secondaryTabs;
  const getAntTabClass = () =>
    styleType === 'primary' ? classes.primaryTab : classes.secondaryTab;

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Stats Information"
        classes={{
          root: getAntTabsClass(),
          indicator: classes.indicator,
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            label={tab.label}
            classes={{
              root: getAntTabClass(),
              selected: classes.antTabSelected,
            }}
          />
        ))}
      </Tabs>
      {children}
    </div>
  );
};

export default CenteredTabs;
