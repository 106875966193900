import React, { FC } from 'react';
import s from './style/progressBar.module.scss';

type PageWrapperType = {
  rootElementWidth: number;
  progressNumber: number;
};

const PageWrapper: FC<PageWrapperType> = ({
  rootElementWidth,
  progressNumber,
}) => (
  <div
    className={`${s.progress__wrapper}`}
    style={{ width: `${rootElementWidth}px` }}
  >
    <h2
      className={`${s.progress__wrapper__done} ${s.progress__wrapper__done__label}`}
      style={{ minWidth: `${progressNumber}%` }}
    >
      <span className={`${s.progress__wrapper__done__label__text}`}>
        Loading
      </span>
    </h2>
  </div>
);
export default PageWrapper;
