/* eslint-disable */
import React, { FC } from 'react';

type FileIconComponentType = {
  color?: string;
  width?: string;
  height?: string;
};

const FileIconComponent: FC<FileIconComponentType> = ({
  width = '20',
  height = '24',
  color = '#C2CEE0',
}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.16667 0C2.32681 0 1.52136 0.33363 0.927495 0.927495C0.33363 1.52136 0 2.32681 0 3.16667V20.5C0 21.3399 0.33363 22.1453 0.927495 22.7392C1.52136 23.333 2.32681 23.6667 3.16667 23.6667H16.1667C17.0065 23.6667 17.812 23.333 18.4058 22.7392C18.9997 22.1453 19.3333 21.3399 19.3333 20.5V6.95833C19.3333 6.69312 19.228 6.43876 19.0404 6.25123L13.0821 0.292893C12.8946 0.105357 12.6402 0 12.375 0H11.8333H3.16667ZM10.8333 2H3.16667C2.85725 2 2.5605 2.12292 2.34171 2.34171C2.12292 2.5605 2 2.85725 2 3.16667V20.5C2 20.8094 2.12292 21.1062 2.34171 21.325C2.5605 21.5437 2.85725 21.6667 3.16667 21.6667H16.1667C16.4761 21.6667 16.7728 21.5437 16.9916 21.325C17.2104 21.1062 17.3333 20.8094 17.3333 20.5V8.5H11.8333C11.281 8.5 10.8333 8.05229 10.8333 7.5V2ZM16.4608 6.5H12.8333V2.87247L16.4608 6.5ZM9.65739 19.3333C9.40456 19.331 9.15243 19.2334 8.95952 19.0405L5.70952 15.7905C5.31899 15.4 5.31899 14.7668 5.70952 14.3763C6.10004 13.9857 6.73321 13.9857 7.12373 14.3763L8.66663 15.9192L8.66663 11.8334C8.66663 11.2811 9.11434 10.8334 9.66663 10.8334C10.2189 10.8334 10.6666 11.2811 10.6666 11.8334L10.6666 15.9192L12.2095 14.3763C12.6 13.9857 13.2332 13.9857 13.6237 14.3763C14.0143 14.7668 14.0143 15.4 13.6237 15.7905L10.3737 19.0405C10.1808 19.2334 9.92869 19.331 9.67586 19.3333"
      fill={color}
    />
  </svg>
);

export default FileIconComponent;
