/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const RedirectPageSlice = createSlice({
  name: 'RedirectPage',
  initialState: {
    isRedirect: false,
  },
  reducers: {
    setIsRedirect: (state: any, action: PayloadAction<boolean>) =>
      void (state.isRedirect = action.payload),
  },
});

export default RedirectPageSlice.reducer;
export const { setIsRedirect } = RedirectPageSlice.actions;
