/* eslint-disable react/jsx-curly-newline */
import React, { FC, ReactNode } from 'react';
import s from './alert.module.scss';
import WarringIcon from '../../Toast/assets/WarringIcon';
import getOS from '../../../utils/functions/getOS';
import { appConfig } from '../../../../constants';
import downloadFileFunc from '../../../utils/functions/downloadFileFunc';

const replaceDownloadWithLink = (text: string, index: number): ReactNode => (
  <React.Fragment key={`download-link-${index}`}>
    {text}
    <button
      type="button"
      onClick={() =>
        downloadFileFunc({
          isNewTab: true,
          linkUrl: appConfig[getOS()],
        })
      }
    >
      download
    </button>
  </React.Fragment>
);

const processText = (text: string): ReactNode[] => {
  const downloadWord = 'download';
  const regex = new RegExp(`\\b${downloadWord}\\b`, 'gi');
  const textParts = text.split(regex);

  return textParts.map((part, index, array) =>
    index < array.length - 1 ? replaceDownloadWithLink(part, index) : part
  );
};

const Alert: FC<{ text: string }> = ({ text }) => (
  <div className={s.alert}>
    <div className={s.alert__icon}>
      <WarringIcon />
    </div>
    <p className={s.alert__text}>{processText(text)}</p>
  </div>
);

export default Alert;
