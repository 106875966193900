import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { IconType } from '../../../../types';

interface IArrow extends IconType {
  isActive?: boolean;
}
const Arrow: FC<IArrow> = ({
  width = 12,
  height = 8,
  color = '#2B39B9',
  isActive = false,
}) => (
  <CSSTransition classNames="arrow-down-anim" timeout={350} in={isActive}>
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.13817 0.691401C1.74889 0.307922 1.11825 0.313464 0.728974 0.696936C0.331345 1.08864 0.325538 1.73573 0.723161 2.12744L6.09064 7.41504L11.4581 2.12744C11.8557 1.73573 11.8499 1.08863 11.4523 0.696936C11.063 0.313463 10.4324 0.307922 10.0431 0.691401L6.09064 4.58505L2.13817 0.691401Z"
        fill={color}
      />
    </svg>
  </CSSTransition>
);

Arrow.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isActive: PropTypes.bool,
};

Arrow.defaultProps = {
  width: 12,
  height: 8,
  color: '#2B39B9',
  isActive: false,
};

export default Arrow;
