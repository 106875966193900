import React, { FC } from 'react';
import s from './style/UnionInfo.module.scss';

const UnionInfoNoData: FC = ({ children }) => (
  <div className={`${s.noData__wrapper}`}>
    <div
      className={`${s.wrapper__container} row`}
      style={{ flexDirection: 'column' }}
    >
      <h1 className={`${s.noData__title}`}>No CU Member Data </h1>
      <div className={`${s.noData__text}`}>
        There is no uploaded member data (AIRES files), please upload the data
        to review persona profile of your members. Before that you could review
        only general data based on geographical locations
      </div>
      <div className={`${s.noData__btn}`}>{children}</div>
    </div>
  </div>
);

export default UnionInfoNoData;
