/* eslint-disable */
import React, { FC } from 'react';
import styles from './style/NumberChartWidget.module.scss';
import s from '../../style/ImpactReportPage.module.scss';
import { BarChart, CircleTooltip } from '../../../../components';
import abbreviateNumber from '../../../../utils/functions/abbreviateNumber';
import LoadingNumberChartWidget from './LoadingNumberChartWidget';

export interface BarChartProps {
  data: object;
  title: string;
  legendTitle: string[];
  totalLabel: string;
  legendData: string[];
  isEmpty: boolean;
}

type NumberChartWidgetType<T> = {
  cuName: string;
  totalLabel: string;
  prefix?: string;
  toolTip?: string;
  comparisonName: string;
  totalValue: number;
  chartData: T[];
  comparisonChartData?: T[];
  title: string;
  legendData: string[];
  isLoading: boolean;
};

const NumberChartWithoutComparison: FC<NumberChartWidgetType<string>> = ({
  cuName,
  comparisonName,
  totalValue,
  totalLabel,
  chartData,
  legendData,
  comparisonChartData,
  isLoading,
  title,
  prefix = '',
  toolTip,
}) =>
  isLoading ? (
    <LoadingNumberChartWidget
      title={title}
      tooltip={toolTip}
      isComparison={!!comparisonChartData}
    />
  ) : (
    <div className={`${s.chart}`}>
      <>
        <div className="col-3" style={{ paddingRight: 0 }}>
          <h4 className={s.chartSubTitle}>
            {title}
            {toolTip && (
              <span>
                {' '}
                <CircleTooltip
                  fullWidth
                  description={
                    <div className={`${s.tooltip__text}`}>{toolTip}</div>
                  }
                />
              </span>
            )}
          </h4>
          <div className={styles.numberBlock__total}>
            <div className={styles.numberBlock__total__label}>{totalLabel}</div>
            <div className={styles.numberBlock__total__value}>
              {`${prefix && prefix}${abbreviateNumber(totalValue)}`}
            </div>
          </div>
        </div>
        <div className="col-9" style={{ padding: 0 }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              textAlign: 'left',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div style={{ flex: 'auto' }}>
              <BarChart
                isEmpty={chartData.length > 0}
                legendTitle={[
                  {
                    name: cuName,
                  },
                  {
                    name: comparisonName,
                  },
                ]}
                data={[
                  {
                    emphasis: {
                      focus: 'series',
                    },
                    blur: {
                      itemStyle: {
                        color: 'rgba(33, 3, 3, 1)',
                      },
                    },
                    name: cuName,
                    type: 'bar',
                    label: {
                      show: true,
                      color: 'inherit',
                      position: 'top',
                      valueAnimation: true,
                      formatter: '{@score}%',
                    },
                    data: chartData,
                  },
                  comparisonChartData && {
                    emphasis: {
                      focus: 'series',
                    },
                    blur: {
                      itemStyle: {
                        color: 'rgba(33, 3, 3, 1)',
                      },
                    },
                    name: comparisonName,
                    type: 'bar',
                    label: {
                      show: true,
                      color: 'inherit',
                      position: 'top',
                      valueAnimation: true,
                      formatter: '{@score}%',
                    },
                    data: comparisonChartData,
                  },
                ]}
                legendData={legendData}
                title=""
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );

export default NumberChartWithoutComparison;
