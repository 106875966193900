import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import LoadDataInProgress from './assets/loadDataInProgress.svg';
import s from './DataInProgress.module.scss';

interface DataInProgressProps {
  open: boolean;
  setOpen: (boolean: boolean) => void;
}
const useStyles = makeStyles({
  submitButton: {
    backgroundColor: '#52B36B',
    height: '40px',
    '&:hover': {
      backgroundColor: '#5CB082!important',
    },
  },
});
const DataInProgress: FC<DataInProgressProps> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className={`${s.dialog__img}`}>
            <img
              width="230px"
              height="118px"
              src={LoadDataInProgress}
              alt="Load data in progress"
            />
          </div>
          <p className={`${s.dialog__text}`}>
            The systems needs a few minutes to upload files for processing.
            Please, wait a few minutes before reloading page to the review the
            current status of data processing
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions className={`${s.dialog__action}`}>
        <Button
          onClick={handleClose}
          color="primary"
          size="large"
          variant="contained"
          autoFocus
          className={classes.submitButton}
        >
          <span style={{ fontSize: '14px' }}>Got it</span>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DataInProgress;
