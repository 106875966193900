import { UseQueryOptions } from 'react-query';
import httpClient from '../../../helpers/http';

export const mapPFCrows = ({
  selectedRows,
}: {
  selectedRows: {
    mapRows: {
      id: number;
      meaningId: number;
    };
  }[];
}) => httpClient.post('/api/datamanagement/submiteditedpfcrows', selectedRows);

export const GetPFCTabData = ({
  filterBy,
  take = 10,
  page,
  isRefetch,
  sortBy,
  sortDirection,
}: {
  filterBy: 0 | 1 | 2;
  take?: number;
  page?: number;
  isRefetch?: boolean;
  sortBy: string;
  sortDirection: 0 | 1 | 2;
}): UseQueryOptions => ({
  queryKey: [
    'PFC-table-filterBy-',
    filterBy,
    take,
    page,
    isRefetch,
    sortBy,
    sortDirection,
  ],
  queryFn: () =>
    httpClient.get(
      `/api/datamanagement/pfcodedata?filter=${filterBy}${
        page
          ? `&page=${page}&take=${take}&orderByProperty=${sortBy}&orderBy=${sortDirection}`
          : ''
      }`
    ),
});

export const GetIsPFCTabData = ({
  isRefetch,
}: {
  isRefetch: boolean;
}): UseQueryOptions => ({
  queryKey: [`PFC-table-${isRefetch ? '-refetched' : ''}`],
  queryFn: () => httpClient.get('/api/datamanagement/pfcodedata'),
  refetchInterval: isRefetch ? 10000 : false,
  refetchOnWindowFocus: false,
  cacheTime: 0,
  staleTime: 0,
});

export const GetPFCdropdown = (): UseQueryOptions => ({
  queryKey: ['PFC-table-dropdown'],
  queryFn: () => httpClient.get('/api/datamanagement/pfcmeaning'),
});
