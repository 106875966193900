export const SI_SYMBOL = ['', 'k', 'M', 'B', 'T', 'T'];

function abbreviateNumber(number: number | string, toFixedFirstTier?: boolean) {
  if (isNaN(+number)) {
    return 'null';
  }
  // what tier? (determines 4149 4993 9675 7658)
  // eslint-disable-next-line no-bitwise
  const tier = (Math.log10(Math.abs(Number(number))) / 3) | 0;
  if (tier === 0) return Number(number).toFixed(toFixedFirstTier ? 1 : 0);

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = Number(number) / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

export default abbreviateNumber;
