/* eslint-disable */
import React, { FC } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import s from './style/InfoComponent.module.scss';
import abbreviateNumber from '../../../../utils/functions/abbreviateNumber';
import { CircleTooltip } from '../../../../components';
type InfoComponentType = {
  title: string;
  subTitle?: string;
  tooltipText?: string | null;
  value: number;
  isLoading: boolean;
};

const InfoComponent: FC<InfoComponentType> = ({
  title,
  subTitle,
  value,
  isLoading,
  tooltipText,
}) => (
  <div className={` ${s.container}`}>
    <div className={s.container__content}>
      <div className={s.container__content__title}>
        <div className={s.container__content__title__text}>
          {title}{' '}
          {tooltipText && (
            <CircleTooltip
              description={tooltipText}
              fullWidth
              width="380px"
              iconStyle={{ width: '18px', height: '18px' }}
            />
          )}
        </div>
        {subTitle && (
          <div className={s.container__content__title__subText}>{subTitle}</div>
        )}
      </div>
      <div className={s.container__content__value}>
        <div className={s.container__content__value__text}>
          {isLoading ? <Skeleton width="140px" /> : abbreviateNumber(value)}
        </div>
      </div>
    </div>
  </div>
);

export default InfoComponent;
