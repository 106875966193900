/* eslint-disable */
import React, { FC } from 'react';
import ReactECharts from 'echarts-for-react';
import s from '../../style/ImpactReportPage.module.scss';
import abbreviateNumber from '../../../../utils/functions/abbreviateNumber';
import LoadingNumberChartWidget from './LoadingNumberChartWidget';
import Legend from '../../../../components/Legend';

interface DataItem {
  name: String;
  value: any[];
}

interface DataSeries {
  data: DataItem[];
}

export interface BarChartProps {
  data: object;
  legendTitle: any[];
  legendData: string[];
  isEmpty: boolean;
}

type chartData = { valueCount: number; value: string }[];
type NumberChartWidgetType = {
  cuName: string;
  comparisonName: string;
  loans: chartData;
  membersData: chartData;
  comparisonChartData: chartData;
  isLoading: boolean;
};

const NumberChartWidget: FC<NumberChartWidgetType> = ({
  cuName,
  comparisonName,
  loans,
  membersData,
  comparisonChartData,
  isLoading,
}) => {
  const colorPalette = ['#4388F6', '#232B95', '#52B36B'];
  const LegendArray = [
    { name: `${cuName}`, color: '#4388F6' },
    { name: `${cuName.replace('consumers', 'loans')}`, color: '#232B95' },
  ];

  const options = {
    silent: true,
    color: colorPalette,
    textStyle: {
      fontFamily: 'Open Sans',
    },
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#282828',
      borderColor: '#282828',
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        color: '#FFFFFF',
      },
      padding: 16,
      formatter(params: any) {
        const colorSpan = (color: any) =>
          `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;border:0.7px solid #fff;background-color:${color}"></span>`;
        const colorSpanSubItems = (color: any) =>
          `<span style="display:inline-block;margin-left:17px;margin-right:5px;border:0.7px solid #fff;border-radius:10px;width:4px;height:4px;background-color:${color}"></span>`;
        let rez = `<p style="font-size:18px;font-family: Open Sans;font-weight: 700;">${params[0].axisValue}</p>`;
        params.forEach((item: any) => {
          const xx = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpan(
            item.color
          )} ${item.seriesName}</p>`;
          let xx1 = `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpanSubItems(
            item.color
          )}Count: ${abbreviateNumber(item.data.valueCount)}</p>`;
          const xx2 =
            `<p style="font-size:12px;font-family: Open Sans;font-weight: 600;padding:0;margin:0">${colorSpanSubItems(
              item.color
            )}Percentage: ${item.data.value.toLocaleString('en')}%` + '</p>';
          rez += xx;
          rez += xx1 += xx2;
        });
        return rez;
      },
      extraCssText:
        'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); border-radius: 6px;',
      rich: {
        a: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '12px',
          color: '#282828',
        },
        b: {
          padding: [0, 0, 6, 0],
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '26px',
          align: 'center',
          color: '#282828',
        },
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          borderColor: 'black',
          borderWidth: 5,
          opacity: 0.5,
        },
      },
    },

    legend: {
      show: null,
    },
    grid: {
      show: false,
      left: '-20',
      right: '0',
      bottom: '5%',
      containLabel: true,
    },
    xAxis: [
      {
        axisTick: {
          show: false,
        },
        splitLine: {
          show: true,
          boundaryGap: true,
          lineStyle: {
            color: '#EFF2F7',
            width: 2,
          },
        },
        type: 'category',
        data: [
          'Rural',
          'Economically Distressed',
          'Economically Distressed \n  \n & Rural',
          'Neither Economically\n \nDistressed nor Rural',
        ],
        axisLabel: {
          interval: 0,
        },
        offset: 8,
        axisLine: {
          show: false,
          lineStyle: {
            color: '#7A89A7',
          },
        },
      },
    ],
    yAxis: [
      {
        show: false,
        splitLine: {
          show: false,
          boundaryGap: false,
        },
        type: 'value',
      },
    ],
    series: [
      {
        barWidth: '15%',
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            color: 'rgba(33, 3, 3, 1)',
          },
        },
        name: LegendArray[0].name,
        type: 'bar',
        label: {
          show: true,
          color: 'inherit',
          position: 'top',
          valueAnimation: true,
          formatter: '{@score}%',
        },
        data: membersData,
      },
      {
        barWidth: '15%',
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            color: 'rgba(33, 3, 3, 1)',
          },
        },
        name: LegendArray[1].name,
        type: 'bar',
        label: {
          show: true,
          color: 'inherit',
          position: 'top',
          valueAnimation: true,
          formatter: '{@score}%',
        },
        data: loans,
      },
      {
        barWidth: '15%',
        emphasis: {
          focus: 'series',
        },
        blur: {
          itemStyle: {
            color: 'rgba(33, 3, 3, 1)',
          },
        },
        name: comparisonName,
        type: 'bar',
        label: {
          show: true,
          color: 'inherit',
          position: 'top',
          valueAnimation: true,
          formatter: '{@score}%',
        },
        data: comparisonChartData,
      },
    ],
  };
  if (isLoading) {
    return <LoadingNumberChartWidget title={'By Area Type'} withLabel />;
  }
  return (
    <div className={`${s.chart}`}>
      <div
        className="col-12"
        style={{
          paddingRight: 0,
          width: '100%',
          flex: '1',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
          }}
        >
          <div className={`${s.legend}`} style={{ margin: 0 }}>
            <div className={`${s.legend__wrapper}`} style={{ margin: 0 }}>
              <h3 className={`${s.legend__wrapper__text}`}>By Area Type</h3>
            </div>
            <Legend cuName={LegendArray} comparisonName={comparisonName} />
          </div>
          <span style={{ marginTop: '-35px', height: '100%' }}>
            <ReactECharts
              style={{ marginTop: '4px', height: '100%' }}
              option={options}
              notMerge={true}
              lazyUpdate={true}
              opts={{ renderer: 'canvas' }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NumberChartWidget;
