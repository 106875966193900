import { configureStore } from '@reduxjs/toolkit';
import ImpactReportSlice from '../containers/ImpactReportPage/Slice/impactReportSlice';
import RedirectPageSlice from '../containers/RedirectPage/RedirectPageSlice';

export const store = configureStore({
  reducer: {
    impactReport: ImpactReportSlice,
    redirectPage: RedirectPageSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
