/* eslint-disable */
import React, { FC } from 'react';
import ReactECharts from 'echarts-for-react';
import s from './Gauge.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import { Theme, makeStyles } from '@material-ui/core/styles';

export interface GaugeChartType {
  value: number | string;
  label: string;
  pieData: object[];
  statsLabel: string;
  statsPrefix?: string;
  line?: 'solid' | 'dashed' | 'none';
  statsValue: number;
  isCustomData?: boolean;
  medianValue?: number;
}
const GaugeCDFIChart: FC<GaugeChartType> = ({
  value,
  pieData,
  label,
  statsValue,
  statsLabel,
  statsPrefix = '',
  line = 'none',
  isCustomData = false,
  medianValue = 60,
}) => {
  const colorPalettePositive = isCustomData
    ? [
        'rgba(196, 223, 203, 1)',
        'rgba(147, 208, 163, 1)',
        'rgba(82, 179, 107, 1)',
        'rgba(52, 115, 68, 1)',
        'rgba(122, 137, 167, 1)',
        'rgba(228, 231, 237, 1)',
      ]
    : [
        'rgba(147, 208, 163, 1)',
        'rgba(82, 179, 107, 1)',
        'rgba(52, 115, 68, 1)',
        'rgba(122, 137, 167, 1)',
        'rgba(228, 231, 237, 1)',
      ];
  const colorPaletteNegative = isCustomData
    ? [
        'rgba(171, 21, 33, 0.5)',
        '#AB1521',
        '#D03340',
        '#F35461',
        'rgba(122, 137, 167, 1)',
        'rgba(228, 231, 237, 1)',
      ]
    : [
        '#AB1521',
        '#D03340',
        '#F35461',
        'rgba(122, 137, 167, 1)',
        'rgba(228, 231, 237, 1)',
      ];
  const option = {
    fontFamily: 'Open Sans',
    tooltip: {
      trigger: 'item',
      backgroundColor: '#282828',
      borderColor: '#282828',
      textStyle: {
        color: '#FFFFFF',
      },
      padding: 16,
      formatter(params: any) {
        const itemName = params.data.name;
        const colorSpan = (color: any) =>
          `<span style="display:inline-block;border:1px solid #fff;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${color}"></span>`;
        return (
          `<p style="padding:0;margin:0">${colorSpan(
            params.color
          )}${itemName}: ${params.data.value}%` + '</p>'
        );
      },
      position(point: any, params: any, dom: any, rect: any, size: any) {
        // The tooltip is displayed to the right when the mouse is on the left, and the tooltip is displayed to the left when the mouse is on the right
        const obj: any = { top: point[1] };
        obj[['left', 'right'][+(point[0] < size.viewSize[0] / 5)]] = 5;
        return obj;
      },
      axisPointer: {
        type: 'shadow',
        shadowStyle: {
          borderColor: 'black',
          borderWidth: 5,
          opacity: 0.5,
        },
      },
    },

    legend: {
      show: false,
      type: 'scroll',
      textStyle: {
        fontFamily: 'Open Sans',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '12px',
        color: '#282828',
      },
      top: 'middle',
      left: '56%',
      orient: 'vertical',
      icon: 'circle',
      align: 'left',
      itemGap: 16,
    },
    label: {
      fontSize: 28,
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontStyle: 'normal',
      color: value > medianValue ? '#7BD5A7' : '#f35461',
      formatter: `{${value}}%`,
    },

    color: value > medianValue ? colorPalettePositive : colorPaletteNegative,
    series: [
      {
        type: 'pie',
        radius: ['60%', '73%'],
        avoidLabelOverlap: false,
        legendHoverLink: false,
        center: ['40%', '50%'],
        labelLayout: {
          hideOverlap: true,
        },
        label: {
          normal: {
            width: '96',
            border: 100,
            show: true,
            position: 'center',
            formatter: `${value}%`,
            rich: {
              a: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontStyle: 'normal',
                fontSize: '12px',
                color: value > medianValue ? '#7BD5A7' : '#f35461',
              },
            },
          },
          emphasis: {
            focus: 'series',
            blurScope: 'coordinateSystem',
            width: '96',
            backgroundColor: 'rgba(255, 255, 255, 1)',
            show: true,
            formatter: '{b|{c}%}',
            rich: {
              a: {
                fontFamily: 'Open Sans',
                fontWeight: 600,
                fontStyle: 'normal',
                fontSize: '12px',
                color: value > medianValue ? '#7BD5A7' : '#f35461',
              },
            },
          },
        },
        itemStyle: {
          normal: {
            label: {
              show: false,
            },
          },
          emphasis: {
            label: {
              show: true,
            },
          },
        },
        emphasis: {
          label: {
            show: false,
            fontSize: '10',
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        data: pieData,
      },
    ],
  };

  const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: '#282828',
    },
    tooltip: {
      fontSize: '12px',
      minWidth: '320px',
      padding: '16px',
      backgroundColor: '#282828',
      color: 'white',
    },
  }));
  const classes = useStylesBootstrap();
  const legendData = isCustomData
    ? [
        {
          color:
            value > medianValue
              ? 'rgba(196, 223, 203, 1)'
              : 'rgba(171, 21, 33, 0.5)',
          name: 'Custom Investment Area (CIA)',
        },
        {
          color: value > medianValue ? 'rgba(147, 208, 163, 1)' : '#AB1521',
          name: 'Investment Area (IA)',
        },
        {
          color: value > medianValue ? 'rgba(82, 179, 107, 1)' : '#D03340',
          name: 'Low-Income Target Population (LITP)',
        },
        {
          color: value > medianValue ? 'rgba(52, 115, 68, 1)' : '#F35461',
          name: 'Both (IA & LITP)',
        },
        {
          color: 'rgba(122, 137, 167, 1)',
          name: 'Other Targeted Population (OTP) ',
          isDisabled: true,
        },
        {
          color: 'rgba(228, 231, 237, 1)',
          name: 'No',
        },
      ]
    : [
        {
          color: value > medianValue ? '#2DB295' : '#AB1521',
          name: 'Investment Area (IA)',
        },
        {
          color: value > medianValue ? '#0E806F' : '#D03340',
          name: 'Low-Income Target Population (LITP)',
        },
        {
          color: value > medianValue ? '#0E665B' : '#F35461',
          name: 'Both (IA & LITP)',
        },
        {
          color: '#7A89A7',
          name: 'Other Targeted Population (OTP) ',
          isDisabled: true,
        },
        {
          color: '#E4E7ED',
          name: 'No',
        },
      ];
  const mapList = () => {
    return legendData.map((i, index: number) => {
      if (i.isDisabled) {
        return (
          <Tooltip
            classes={classes}
            title={
              'This functionality is not yet implemented in the CDFI calculations'
            }
            interactive
            arrow
          >
            <span style={{ padding: '4px 0' }}>
              <span style={{ width: '4%' }}>
                <span
                  className={`${s.chart__wrapper__legend__row__circle}`}
                  style={{ backgroundColor: i.color }}
                />{' '}
              </span>
              <span
                className={`${s.chart__wrapper__legend__row__text}`}
                style={{ color: '#7A89A7' }}
              >
                {i.name}
              </span>
            </span>
          </Tooltip>
        );
      }
      return (
        <span style={{ padding: '4px 0' }}>
          <span style={{ width: '4%' }}>
            <span
              className={`${s.chart__wrapper__legend__row__circle}`}
              style={{ backgroundColor: i.color }}
            />{' '}
          </span>
          <span className={`${s.chart__wrapper__legend__row__text}`}>
            {i.name}
          </span>
        </span>
      );
    });
  };
  return (
    <div className={`${s.gauge__wrapper}`}>
      <div>
        <h3 className={`${s.gauge__wrapper__label}`}>{label}</h3>
        <p className={`${s.gauge__wrapper__secondary}`}>
          (at least 60% needed to qualify)
        </p>
      </div>
      <div>
        <div className={`${s.chart__wrapper}`}>
          <div className="col-sm-6" style={{ padding: 0 }}>
            <ReactECharts
              className={`${s.chart__wrapper__pie}`}
              option={option}
            />
          </div>
          <div
            className={`${s.chart__wrapper__legend} col-sm-6`}
            style={{ padding: 0 }}
          >
            {mapList()}
          </div>
        </div>
        <div className={`${s.gauge__stats__line}`} />
        <div
          className={`${s.gauge__stats} ${s.gauge__stats__CDFI}`}
          style={{
            backgroundColor:
              value > medianValue
                ? 'rgba(44, 179,146, 0.08)'
                : 'rgba(243, 84,97, 0.08)',
          }}
        >
          <h4 className={`${s.gauge__stats__CDFI__label}`}>{statsLabel}</h4>

          <h2
            className={`${s.gauge__stats__CDFI__value}`}
            style={{ color: value > medianValue ? '#7BD5A7' : '#f35461' }}
          >
            {`${statsPrefix}${statsValue.toLocaleString('en')}`}
          </h2>
        </div>
      </div>
    </div>
  );
};

export { GaugeCDFIChart };
