/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useEffect, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Oval } from 'react-loader-spinner';
import s from './style/TagsInput.module.scss';
import style from '../Autocomplete/style/Autocomplete.module.scss';
import Autocomplete from '../Autocomplete';
import { removeTag } from '../../Slice/impactReportSlice';
import type { RootState } from '../../../../store/store';

const TagsInput: FC = () => {
  const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: '#282828',
    },
    tooltip: {
      fontSize: '12px',
      minWidth: '320px',
      padding: '16px',
      backgroundColor: '#282828',
      color: 'white',
    },
  }));
  const classes = useStylesBootstrap();
  const [input, setInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<any[]>([]);
  const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
  const dispatch = useDispatch();
  const items = useAppSelector((state) => state.impactReport);
  const onChange = (e: {
    currentTarget: { value: React.SetStateAction<string> };
  }) => {
    setInput(e.currentTarget.value);
  };

  useEffect(() => {
    const currentTags = items.tagsData;
    if (currentTags) {
      setTags(currentTags);
    } else {
      setTags([]);
    }
  }, [items.tagsData]);

  const removeTags = (indexToRemove: any) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
    dispatch(
      removeTag({
        tagId: indexToRemove,
      })
    );
  };

  return (
    <>
      <div className={s.tags__input}>
        <ul className={s.tags}>
          {tags.map((tag, index) => (
            <li key={tag.locationType} className={s.tag}>
              <span className={s.tag__title}>{tag.value}</span>
              <span
                className={s.tag__closeIcon}
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </li>
          ))}
        </ul>
        {items.tempLocation.value ? (
          <div className={style.autocomplete}>
            <input
              type="text"
              disabled={!items.tempLocation.value}
              className={style.autocomplete__input}
              onChange={onChange}
              value={input}
              placeholder="Press enter to add tags"
            />
            {isLoading && (
              <Oval
                height={22}
                width={22}
                color="#232B95"
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#EEF1F6"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            )}
          </div>
        ) : (
          <Tooltip
            classes={classes}
            title="This functionality is available only after choosing a location type"
            interactive
            arrow
          >
            <input
              type="text"
              disabled={!items.tempLocation.value}
              className={style.autocomplete__input}
              onChange={onChange}
              style={{ cursor: 'not-allowed', background: 'none' }}
              value={input}
              placeholder="Press enter to add tags"
            />
          </Tooltip>
        )}
      </div>
      <Autocomplete
        value={input}
        setInput={(e: string) => setInput(e)}
        setIsLoading={(e: boolean) => setIsLoading(e)}
      />
    </>
  );
};

export default TagsInput;
