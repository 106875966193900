/* eslint-disable */
import React, { FC } from 'react';
import { Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme } from '@material-ui/core/styles';
import downloadFileFunc from '../../utils/functions/downloadFileFunc';

export interface IDownloadLink {
  dateRange?: string;
  newTab?: boolean;
  file?: string | React.ReactElement;
  isEnabled?: boolean;
  hrefLink?: string;
  btnText: string;
  style?: React.CSSProperties;
  icon: string | React.ReactElement;
  description?: string;
}

const DownloadLink: FC<IDownloadLink> = ({
  hrefLink,
  style,
  btnText,
  icon,
  file,
  description,
  isEnabled = false,
  newTab = true,
}) => {
  const useStyles = makeStyles((theme) => ({
    btn: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: '#2B39B9',
      background: '#FFFFFF',
      boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.05)',
      borderRadius: '4px',
      width: '112px',
      height: '48px',
      padding: '16px',
      '&:hover': {
        color: '#191960',
        filter: 'drop-shadow(0px 2px 15px rgba(122, 137, 167, 25%))',
        background: `${style ? '#22447D!important' : 'white'}`,
      },
    },
  }));

  const classes = useStyles();
  const useStylesBootstrap = makeStyles((theme: Theme) => ({
    arrow: {
      color: '#282828',
    },
    tooltip: {
      width: '270px',
      padding: '16px',
      backgroundColor: '#282828',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      color: 'white',
    },
  }));
  const tooltipClass = useStylesBootstrap();
  const downloadObject = {
    linkUrl: hrefLink,
    isNewTab: newTab,
    fileData: file,
  };
  if (description) {
    return (
      <Tooltip
        placement="top"
        classes={tooltipClass}
        title={description || ''}
        arrow
      >
        <Button
          disabled={isEnabled}
          startIcon={
            typeof icon === 'string' ? <img src={icon} alt="file-icon" /> : icon
          }
          size="large"
          className={classes.btn}
          variant="contained"
          style={style}
          color="secondary"
          onClick={() => downloadFileFunc(downloadObject)}
        >
          <span style={{ fontSize: '14px' }}>{btnText}</span>
        </Button>
      </Tooltip>
    );
  }
  return (
    <Button
      disabled={isEnabled}
      startIcon={
        typeof icon === 'string' ? <img src={icon} alt="file-icon" /> : icon
      }
      size="large"
      className={classes.btn}
      variant="contained"
      style={style}
      color="secondary"
      onClick={() => downloadFileFunc(downloadObject)}
    >
      <span style={{ fontSize: '14px' }}>{btnText}</span>{' '}
    </Button>
  );
};

export { DownloadLink };
