/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import getOS from '../../../utils/functions/getOS';
import { appConfig } from '../../../../constants';
import downloadFileFunc from '../../../utils/functions/downloadFileFunc';
import s from './DownloadWidget.module.scss';
import CheckCircleComponent from '../../../containers/assets/CheckCircleComponent';
import DownloadIcon from '../../../containers/assets/DownloadIcon';

const useStyles = makeStyles({
  cancelButton: {
    '&:hover': {
      background: 'rgba(43, 57, 185, 0.8)!important',
    },
  },
  submitButton: {
    '&:hover': {
      // opacity: 0.8,
      // filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
  },
  button: {
    backgroundColor: 'green!important',
    color: '#fffff',
    '&:hover': {
      backgroundColor: 'green!important',
    },
  },
});

const options = ['Mac', 'Windows'];

const DownloadWidget: FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [OS, setOS] = useState<'Windows' | 'Mac'>('Windows');
  const [status, setStatus] = useState<1 | 2 | 3>(1);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    setOS(getOS());
  }, []);

  const handleDownloadClick = (os: 'Windows' | 'Mac') => {
    setProgress(0);
    setStatus(2);
    downloadFileFunc({
      isNewTab: true,
      linkUrl: appConfig[os],
      thenFunc: SuccessDownload,
      progressFunc: setProgress,
    });
  };

  const SuccessDownload = () => {
    setStatus(3);
    setTimeout(() => setStatus(1), 1800);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const classes = useStyles();
  const States = {
    1: (
      <>
        <ButtonGroup
          variant="contained"
          color="secondary"
          aria-label="split button"
          style={{
            width: '100%',
            boxShadow: 'none',
            height: '40px',
          }}
        >
          <Button
            className={classes.cancelButton}
            style={{
              width: '80%',
              borderRadius: '4px 0 0 4px',
              borderRight: '1px solid rgba(255, 255, 255, 0.5)',
              color: '#ffff',
              background: '#2B39B9',
            }}
            onClick={() => handleDownloadClick(OS)}
          >
            <span
              style={{
                fontSize: '14px',
                fontWeight: 600,
              }}
            >
              Download Data Preparer for {OS}
            </span>
          </Button>
          <Button
            className={classes.cancelButton}
            style={{
              borderRadius: '0 4px 4px 0',
              color: '#ffff',
              background: '#2B39B9',
              fontSize: '20px',
              width: '20%',
            }}
            color="secondary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <KeyboardArrowDownIcon />
          </Button>
        </ButtonGroup>
        <CSSTransition
          in={open}
          classNames="my-node"
          timeout={100}
          unmountOnExit
        >
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={`${s.dropdown}`}>
              <>
                {options.map((option) => (
                  <span
                    onClick={() => {
                      handleDownloadClick(option as 'Windows' | 'Mac');
                    }}
                    key={option}
                    className={`${s.dropdown__item}`}
                  >
                    Download Data Preparer for {option}
                  </span>
                ))}
              </>
            </div>
          </ClickAwayListener>
        </CSSTransition>
      </>
    ),
    2: (
      <div
        className={`${s.button} ${s.button__loading}`}
        style={{ background: '#52B36B', cursor: 'wait' }}
      >
        <span className={s.button__loading__text}>
          <span style={{ marginRight: '8px' }}>
            <DownloadIcon />
          </span>
          <span>Downloading</span>
        </span>
        <div className={s.button__loader} style={{ width: `${progress}%` }} />
      </div>
    ),
    3: (
      <div className={`${s.button} ${s.button__done}`}>
        <span style={{ marginRight: '8px' }}>
          <CheckCircleComponent />
        </span>
        <span> Done </span>
      </div>
    ),
  };
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition timeout={110} key={status} classNames="item">
        <div>{States[status]}</div>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default DownloadWidget;
