/* eslint-disable */
import React, {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
  useMemo,
} from 'react';
import RaceChart from './widgets/RaceChart';
import { useQueries } from 'react-query';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@material-ui/core';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import {
  PageWrapper,
  Modal,
  EmptyState,
  Loader,
  CircleTooltip,
} from '../../components';
import FileIconComponent from '../assets/FileIconComponent';
import TotalBenefitsWidget, {
  EnabledTotalMembersType,
} from './widgets/TotalBenefitsWidget/index';
import UnionImpact from './widgets/UnionImpact';
import NumberChartWidget from './widgets/NumberChartWidget';
import ProgressBar from './widgets/ProgressBar';
import AlertDialog from './widgets/Alert';
import EconomicContributionBlock from './widgets/EconomicContributionBlock';
import LocationFilter from './widgets/LocationFilter';
import NumberChartWithoutComparison from './widgets/NumberChartWidget/NumberChartWithoutComparison';
import InfoComponent from './widgets/InfoComponent';
import {
  GetCombinedStatsData,
  ResponseType,
  GetEnabledTotalMembers,
} from '../../api';
import type { RootState } from '../../store/store';
import useElementSize from '../../utils/hooks/useElementSIze';
import { PageType } from '../PageType';
import MyDocument from './widgets/Export';
import DownloadDropdown from './widgets/DownloadDropdown';
import s from './style/ImpactReportPage.module.scss';

type TotalMembersType = {
  totalBenefit: number;
  benefitPerMember: number;
  membersPerSpecificLocation: number;
};

type ChartDataResponse = {
  name: string;
  shippingAddress: string;
  billingAddress: string;
  email: string;
  description: string;
  logoUrl: string;
};

interface IImpactReportPage extends PageType {
  isLeague: boolean;
  CuData: CUdataType;
}

type CreditScoreTiersType = {
  poor: number;
  fair: number;
  good: number;
  veryGood: number;
  exceptional: number;
  median: number;
};

type MembersPopulationType = {
  total: number;
  economicallyDistressed: number;
  economicallyDistressedPercent: number;
  rural: number;
  ruralPercent: number;
  both: number;
  bothPercent: number;
  none: number;
  nonePercent: number;
};

type RaceType = {
  white: number;
  black: number;
  native: number;
  asian: number;
  islander: number;
  other: number;
  twoPlus: number;
  hispanic: number;
  matchPercent: number | null;
};

type RaceChartType = {
  raceWA: null | RaceType;
  raceBISG: null | RaceType;
  raceBIFSG: null | RaceType;
};

type CombinedStatsType = {
  estimatedMembers: number | null;
  estimatedMembersPercent: number | null;
  directJobs: number;
  totalJobs: number;
  economicContribution: number;
  totalBenefitData: TotalMembersType;
  creditScoreTiers: CreditScoreTiersType;
  membersLoansPopulationStats: {
    memberStats: MembersPopulationType;
    loanStats: MembersPopulationType;
    areaStats: MembersPopulationType;
  };
  householdData: {
    memberStats: {
      medianHouseholdIncome: number;
      householdIncome: {
        from0to25k: number;
        from25to50k: number;
        from50to75k: number;
        from75to100k: number;
        from100to150k: number;
        over150k: number;
      };
    };
    locationStats: {
      medianHouseholdIncome: number;
      householdIncome: {
        from0to25k: number;
        from25to50k: number;
        from50to75k: number;
        from75to100k: number;
        from100to150k: number;
        over150k: number;
      };
    };
  };
  raceData: {
    memberStats: RaceChartType;
    locationStats: RaceChartType;
  };
};
const crumbs = [
  { name: 'Home', path: '/home' },
  { name: 'Analyze', path: '/analyze' },
  { name: 'Impact Report', path: '/' },
];

type CUdataType = {
  shippingAddress: string;
  billingAddress: string;
  email: string;
  description: string;
  logoUrl: string;
  cuName: string;
};

const ImpactReportPage: FC<IImpactReportPage> = memo(
  ({ isLeague, CuData, isEmptyState }) => {
    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const items = useAppSelector((state) => state.impactReport);
    const pdfRef = useRef(null);
    const [squareRef, { width }] = useElementSize();

    const [locationName, setLocationName] = useState('All Geographies');
    const [selected, setSelected] = useState('raceBIFSG');
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isFirstRequest, setIsFirstRequest] = useState(false);
    const [locationIds, setLocationIds] = useState<number[]>([]);
    const [loadingItemsPercent, setLoadingItemsPercent] = useState(0);
    const [loadingItemsCount, setLoadingItemsCount] = useState(0);
    const [enabledTotalMembersLinks, setEnabledTotalMembersLinks] =
      useState<EnabledTotalMembersType>({
        isLoanMapped: false,
        isShareMapped: false,
        isPfcMapped: false,
        calcStatus: 1,
      });
    const [totalMembersObject, setTotalMembersObject] =
      useState<TotalMembersType>({
        totalBenefit: 0,
        benefitPerMember: 0,
        membersPerSpecificLocation: 0,
      });
    const [barCharts, setBarCharts] = useState({
      monteCarloRace: {
        raceBIFSG: null,
        raceBISG: null,
        raceWA: null,
      },
      creditScoreTiersArray: [],
      customLocationRace: {
        raceBIFSG: null,
        raceBISG: null,
        raceWA: null,
      },
      valueOfOwnerOccupiedHousingUnitsArray: [],
      valueOfOwnerOccupiedHousingUnitsArrayComparison: [],
    });
    const [areaTypeChart, setAreaTypeChart] = useState({
      allLocationsMember: [] as { valueCount: number; value: string }[],
      customLocationMember: [] as { valueCount: number; value: string }[],
      allLocationLoans: [] as { valueCount: number; value: string }[],
    });
    const [numberStats, setNumberStats] = useState({
      totalLoans: 0,
      totalMembers: 0,
      totalPopulation: 0,
      directJobsValue: 0,
      householdIncome: 0,
      creditScoreTiersMedianValue: 0,
      economicContributionValue: 0,
      totalJobsCount: 0,
    });
    const [estimatedMembers, setEstimatedMembers] = useState(0);
    const [estimatedMembersPercent, setEstimatedMembersPercent] = useState<
      number | null
    >(11); // if estimatedMembers percent more than 10 we should show alert only for leagues;
    const fullWindow = items.isFirstOpen && isLeague;
    const [open, setOpen] = useState(fullWindow);

    const IsTMTenabled = useMemo(
      () =>
        Object.values({
          loan: enabledTotalMembersLinks.isLoanMapped,
          share: enabledTotalMembersLinks.isShareMapped,
          pfc: enabledTotalMembersLinks.isPfcMapped,
        }).every((value) => value === true),
      [enabledTotalMembersLinks]
    );

    const results = useQueries(
      locationIds.length < 1
        ? []
        : [
            GetCombinedStatsData({
              locationIds,
              combinedStatus: enabledTotalMembersLinks.calcStatus,
              isEnabled: isFirstRequest,
            }),
            GetEnabledTotalMembers({
              locationIds,
              isRefetch: true,
            }),
          ]
    ) as any;

    const CombinedStatsData = results[0]
      ?.data as ResponseType<CombinedStatsType>;
    const EnabledTotalMembers = results[1]
      ?.data as ResponseType<EnabledTotalMembersType>;

    const handlePrint = useReactToPrint({
      content: () => pdfRef.current,
      pageStyle: '@page { size: 816px 1054px }',
      documentTitle: `Impact_Report_${new Date().toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit',
      })}`,
      removeAfterPrint: true,
    });

    useEffect(() => {
      if (items?.activeTagsData) {
        const FilteredLocationIds = items?.activeTagsData
          .map((item) => item?.entityId)
          .filter((value, index, self) => self.indexOf(value) === index);
        setLocationIds(FilteredLocationIds as any);
        setLocationName(
          items.activeLocation.value === 'All Geographies'
            ? 'All Geographies'
            : items.tagsData.length > 1
            ? `selected communities on (${items.activeLocation.value})`
            : items.activeTagsData.length > 0
            ? `${items.activeTagsData[0].value} (${items.activeLocation.value})`
            : ''
        );
      }
    }, [items.activeTagsData, items.activeLocation, items.tagsData]);

    const reactToPrintTrigger = useCallback(
      () => (
        <Button
          startIcon={<FileIconComponent />}
          style={{
            maxWidth: '212px',
            boxShadow: 'none',
            height: '48px',
            width: '212px',
            borderRight: '1px solid #FFFFFF',
            background: '#2B39B9',
            color: '#FFFFFF',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontSize: '14px', fontWeight: 600 }}>Export</span>
        </Button>
      ),
      []
    );

    useEffect(() => {
      if (isLeague && !items.isModalOpen && estimatedMembersPercent < 10) {
        setIsAlertOpen(true);
      }
    }, [isLeague, items.isModalOpen, estimatedMembersPercent]);

    useEffect(() => {
      if (EnabledTotalMembers?.result) {
        if (!isFirstRequest) {
          setIsFirstRequest(true);
        }
        if (
          JSON.stringify(EnabledTotalMembers?.result) !==
          JSON.stringify(enabledTotalMembersLinks)
        ) {
          setEnabledTotalMembersLinks(EnabledTotalMembers?.result);
        }
      }
    }, [EnabledTotalMembers]);

    useEffect(() => {
      if (CombinedStatsData?.result) {
        const { result } = CombinedStatsData;
        const {
          totalBenefitData,
          totalJobs,
          householdData,
          raceData,
          economicContribution,
          directJobs,
          creditScoreTiers,
          membersLoansPopulationStats,
        } = result;

        setEstimatedMembersPercent(result.estimatedMembersPercent);
        setEstimatedMembers(result.estimatedMembers ?? 0);
        setNumberStats({
          totalLoans: membersLoansPopulationStats.loanStats.total,
          totalPopulation: membersLoansPopulationStats.areaStats.total,
          totalMembers: membersLoansPopulationStats.memberStats.total,
          creditScoreTiersMedianValue: creditScoreTiers.median,
          totalJobsCount: totalJobs,
          economicContributionValue: economicContribution,
          directJobsValue: directJobs,
          householdIncome:
            Math.round(
              householdData?.memberStats?.medianHouseholdIncome * 100
            ) / 100,
        });
        setTotalMembersObject(totalBenefitData);
        setAreaTypeChart({
          allLocationsMember: [
            {
              valueCount:
                Math.round(
                  membersLoansPopulationStats.memberStats.rural * 100
                ) / 100,
              value: (
                Math.round(
                  membersLoansPopulationStats.memberStats.ruralPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(
                  membersLoansPopulationStats.memberStats
                    .economicallyDistressed * 100
                ) / 100,
              value: (
                Math.round(
                  membersLoansPopulationStats.memberStats
                    .economicallyDistressedPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(membersLoansPopulationStats.memberStats.both * 100) /
                100,
              value: (
                Math.round(
                  membersLoansPopulationStats.memberStats.bothPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(membersLoansPopulationStats.memberStats.none * 100) /
                100,
              value: (
                Math.round(
                  membersLoansPopulationStats.memberStats.nonePercent * 100
                ) / 100
              ).toFixed(2),
            },
          ],
          allLocationLoans: [
            {
              valueCount:
                Math.round(membersLoansPopulationStats.loanStats.rural * 100) /
                100,
              value: (
                Math.round(
                  membersLoansPopulationStats.loanStats.ruralPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(
                  membersLoansPopulationStats.loanStats.economicallyDistressed *
                    100
                ) / 100,
              value: (
                Math.round(
                  membersLoansPopulationStats.loanStats
                    .economicallyDistressedPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(membersLoansPopulationStats.loanStats.both * 100) /
                100,
              value: (
                Math.round(
                  membersLoansPopulationStats.loanStats.bothPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(membersLoansPopulationStats.loanStats.none * 100) /
                100,
              value: (
                Math.round(
                  membersLoansPopulationStats.loanStats.nonePercent * 100
                ) / 100
              ).toFixed(2),
            },
          ],
          customLocationMember: [
            {
              valueCount:
                Math.round(membersLoansPopulationStats.areaStats.rural * 100) /
                100,
              value: (
                Math.round(
                  membersLoansPopulationStats.areaStats.ruralPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(
                  membersLoansPopulationStats.areaStats.economicallyDistressed *
                    100
                ) / 100,
              value: (
                Math.round(
                  membersLoansPopulationStats.areaStats
                    .economicallyDistressedPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(membersLoansPopulationStats.areaStats.both * 100) /
                100,
              value: (
                Math.round(
                  membersLoansPopulationStats.areaStats.bothPercent * 100
                ) / 100
              ).toFixed(2),
            },
            {
              valueCount:
                Math.round(membersLoansPopulationStats.areaStats.none * 100) /
                100,
              value: (
                Math.round(
                  membersLoansPopulationStats.areaStats.nonePercent * 100
                ) / 100
              ).toFixed(2),
            },
          ],
        });
        setBarCharts({
          valueOfOwnerOccupiedHousingUnitsArray: [
            (
              Math.round(
                householdData?.memberStats.householdIncome.from0to25k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.memberStats.householdIncome.from25to50k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.memberStats.householdIncome.from50to75k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.memberStats.householdIncome.from75to100k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.memberStats.householdIncome.from100to150k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.memberStats.householdIncome.over150k * 100
              ) / 100
            ).toFixed(2),
          ],
          valueOfOwnerOccupiedHousingUnitsArrayComparison: [
            (
              Math.round(
                householdData?.locationStats.householdIncome.from0to25k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.locationStats.householdIncome.from25to50k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.locationStats.householdIncome.from50to75k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.locationStats.householdIncome.from75to100k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.locationStats.householdIncome.from100to150k * 100
              ) / 100
            ).toFixed(2),
            (
              Math.round(
                householdData?.locationStats.householdIncome.over150k * 100
              ) / 100
            ).toFixed(2),
          ],
          creditScoreTiersArray: [
            (Math.round(creditScoreTiers?.poor * 100) / 100).toFixed(2),
            (Math.round(creditScoreTiers?.fair * 100) / 100).toFixed(2),
            (Math.round(creditScoreTiers?.good * 100) / 100).toFixed(2),
            (Math.round(creditScoreTiers?.veryGood * 100) / 100).toFixed(2),
            (Math.round(creditScoreTiers?.exceptional * 100) / 100).toFixed(2),
          ],
          monteCarloRace: raceData?.memberStats,
          customLocationRace: raceData?.locationStats,
        });
      }
    }, [CombinedStatsData]);

    useEffect(() => {
      const loadingCount = results.filter((obj: any) => !obj?.isLoading).length;
      const loadingItemsPercent = (100 * loadingCount) / results.length;

      if (
        loadingItemsCount !== loadingCount ||
        loadingItemsPercent !== loadingItemsPercent
      ) {
        setLoadingItemsCount(loadingCount);
        setLoadingItemsPercent(loadingItemsPercent);
      }
    }, [results, loadingItemsCount, loadingItemsPercent]);

    const openLocationFilter = () => {
      setIsAlertOpen(false);
      setOpen(true);
    };

    return isEmptyState && !isLeague ? (
      <PageWrapper pageTitle="Impact Report" pageCrumbs={crumbs}>
        <EmptyState
          title="You haven’t shared your AIRES files yet"
          text="Please upload your AIRES Files so that AnalyzeCU may analyze your Impact Report data"
        >
          <Modal
            width="180px"
            withIcon={false}
            color="primary"
            buttonText="Upload AIRES Files"
          />
        </EmptyState>
      </PageWrapper>
    ) : (
      <>
        <div ref={squareRef} />
        {!isLeague &&
          (locationIds.length > 1 ||
            (results.length !== loadingItemsCount && (
              <ProgressBar progressNumber={90} rootElementWidth={width} />
            )))}
        <PageWrapper
          pageTitle="Impact Report"
          contextElements={
            <div
              className={s.breadcrumbs__wrapper}
              style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                float: 'left',
              }}
            >
              <div>
                <LocationFilter
                  fullWindow={items.isFirstOpen && isLeague}
                  open={open}
                  setOpen={setOpen}
                />
              </div>
              <div>
                {loadingItemsPercent >= 100 ? (
                  <>
                    <SwitchTransition mode="out-in">
                      <CSSTransition
                        timeout={100}
                        key={IsTMTenabled ? 's' : 'sd'}
                        classNames="item"
                      >
                        <>
                          {IsTMTenabled ? (
                            <DownloadDropdown
                              isLeague={isLeague}
                              url={`/api/impactreporting/exportbenefitperproduct?${locationIds
                                .map(
                                  (
                                    value: number,
                                    index: number,
                                    array: Array<number>
                                  ) =>
                                    `locationIds=${value}${
                                      index === array.length - 1 ? '' : '&'
                                    }`
                                )
                                .join('')}`}
                              icon={<FileIconComponent />}
                              func={handlePrint}
                            />
                          ) : (
                            <Button
                              startIcon={<FileIconComponent />}
                              style={{
                                maxWidth: '212px',
                                boxShadow: 'none',
                                height: '48px',
                                width: '212px',
                                borderRight: '1px solid #FFFFFF',
                                background: '#2B39B9',
                                color: '#FFFFFF',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              onClick={handlePrint}
                            >
                              <span
                                style={{ fontSize: '14px', fontWeight: 600 }}
                              >
                                Export
                              </span>
                            </Button>
                          )}
                        </>
                      </CSSTransition>
                    </SwitchTransition>
                    <MyDocument
                      title={
                        isLeague
                          ? 'Total Annual Benefits across all Credit Union Consumers'
                          : 'Total Annual Benefits to Consumers'
                      }
                      isLeague={isLeague}
                      selected={selected}
                      cuInfo={CuData}
                      impactIn={locationName}
                      refs={pdfRef}
                      data={{
                        isTBTMEnabled: IsTMTenabled,
                        sustainedCreated: {
                          totalJobs: numberStats.totalJobsCount,
                          totalDirect: numberStats.directJobsValue,
                          totalContribution:
                            numberStats.economicContributionValue,
                        },
                        areaData: {
                          members: numberStats.totalMembers,
                          loans: numberStats.totalLoans,
                          population: numberStats.totalPopulation,
                        },
                        totalBenefits: {
                          total: totalMembersObject.totalBenefit,
                          benefits: totalMembersObject.benefitPerMember,
                          members: isLeague
                            ? estimatedMembers
                            : numberStats.totalMembers,
                        },
                        charts: {
                          creditScore: {
                            median: numberStats.creditScoreTiersMedianValue,
                            primaryData: barCharts.creditScoreTiersArray,
                          },
                          race: {
                            primaryData: barCharts.monteCarloRace,
                            comparisonData: barCharts.customLocationRace,
                          },
                          areaData: {
                            name: isLeague
                              ? 'Credit Unions’ consumers in the selected area'
                              : `${CuData.cuName}'s consumers in the selected area`,
                            members: areaTypeChart.customLocationMember,
                            loans: areaTypeChart.allLocationLoans,
                            population: areaTypeChart.allLocationsMember,
                          },
                          income: {
                            primaryData:
                              barCharts.valueOfOwnerOccupiedHousingUnitsArray,
                            comparisonData:
                              barCharts.valueOfOwnerOccupiedHousingUnitsArrayComparison,
                            median: numberStats.householdIncome,
                          },
                        },
                      }}
                    />
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          }
          pageCrumbs={crumbs}
        >
          <>
            {isLeague && results[0]?.isLoading ? (
              <div className="spinner-align">
                <Loader />
              </div>
            ) : (
              <div className="hid">
                <AlertDialog
                  isOpen={isAlertOpen}
                  setOpen={openLocationFilter}
                  setContinue={() => setIsAlertOpen(false)}
                />
                <UnionImpact AreaName={locationName} cuName={CuData.cuName} />
                <TotalBenefitsWidget
                  title={
                    isLeague
                      ? 'Total Annual Benefits across all Credit Union Consumers'
                      : 'Total Annual Benefits to Consumers'
                  }
                  enabledTotalMembersData={{
                    isLoanMapped: isLeague
                      ? true
                      : enabledTotalMembersLinks.isLoanMapped,
                    isShareMapped: isLeague
                      ? true
                      : enabledTotalMembersLinks.isShareMapped,
                    isPfcMapped: isLeague
                      ? true
                      : enabledTotalMembersLinks.isPfcMapped,
                    calcStatus: isLeague
                      ? 2
                      : enabledTotalMembersLinks.calcStatus,
                  }}
                  benefitPerMember={totalMembersObject.benefitPerMember}
                  totalBenefitsToMembers={totalMembersObject.totalBenefit}
                  isLoading={fullWindow || results[0]?.isLoading}
                />
                <EconomicContributionBlock
                  totalJobs={numberStats.totalJobsCount}
                  directJobs={numberStats.directJobsValue}
                  totalContribution={numberStats.economicContributionValue}
                  isLoading={{
                    directJobs: fullWindow || results[0]?.isLoading,
                    totalContribution: fullWindow || results[0]?.isLoading,
                    totalJobs: fullWindow || results[0]?.isLoading,
                  }}
                />
                <div className="row">
                  <div className="col-lg-4">
                    <InfoComponent
                      title="Total Consumers"
                      value={
                        isLeague ? estimatedMembers : numberStats.totalMembers
                      }
                      tooltipText={
                        totalMembersObject.membersPerSpecificLocation === 0
                          ? null
                          : isLeague
                          ? 'Our estimated number of consumers does not sum to the total number of consumers for credit unions headquartered in that area. Instead it is based on estimates of consumers in individual branches, adjusting for the possibility of out of state branches.'
                          : 'Consumers includes credit union members, as well as non-members with loans and deposits listed in AIRES files'
                      }
                      isLoading={fullWindow || results[0]?.isLoading}
                    />
                  </div>
                  <div className={`col-lg-4 ${s.total}`}>
                    <InfoComponent
                      tooltipText={
                        isLeague
                          ? 'Our estimated number of loans does not sum to the total number of loans for credit unions headquartered in that area. Instead it is based on estimates of loans in individual branches, adjusting for the possibility of out of state branches.'
                          : undefined
                      }
                      title="Number of Loans"
                      value={numberStats.totalLoans}
                      isLoading={fullWindow || results[0]?.isLoading}
                    />
                  </div>
                  <div className="col-lg-4">
                    <InfoComponent
                      title="Population"
                      subTitle="in the selected area"
                      value={numberStats.totalPopulation}
                      isLoading={fullWindow || results[0]?.isLoading}
                    />
                  </div>
                </div>
                <NumberChartWidget
                  isLoading={fullWindow || results[0]?.isLoading}
                  cuName={
                    isLeague
                      ? 'Credit Unions’ consumers in the selected area'
                      : `${CuData.cuName}'s consumers in the selected area`
                  }
                  comparisonName="Population in the selected area"
                  membersData={areaTypeChart.allLocationsMember}
                  loans={areaTypeChart.allLocationLoans}
                  comparisonChartData={areaTypeChart.customLocationMember}
                />
                <NumberChartWithoutComparison
                  isLoading={fullWindow || results[0]?.isLoading}
                  cuName={CuData.cuName}
                  prefix="$"
                  totalLabel="Median household income"
                  comparisonName={locationName}
                  totalValue={numberStats.householdIncome}
                  chartData={barCharts.valueOfOwnerOccupiedHousingUnitsArray}
                  comparisonChartData={
                    barCharts.valueOfOwnerOccupiedHousingUnitsArrayComparison
                  }
                  title="Income distribution"
                  legendData={[
                    '$0-$25K',
                    '$25-$50K',
                    '$50K-$75K',
                    '$75K-100K',
                    '$100k-$150K',
                    'Over $150K',
                  ]}
                />
                <NumberChartWithoutComparison
                  isLoading={fullWindow || results[0]?.isLoading}
                  toolTip="Percentage of loans across consumers' credit score tiers. These percentages are computed dividing by the total number of outstanding loans (including undrawn lines of credit), not of consumers or of borrowers. Some consumers may have zero loans and other consumers may have more than one loan. These percentages are also not computed based on the dollar value of loans.  Many reported loans (e.g., lines of credit for credit cards, overdraft protection, and home equity loans) have zero balances much of the time."
                  cuName={CuData.cuName}
                  totalLabel="Median credit score"
                  comparisonName={locationName}
                  totalValue={numberStats.creditScoreTiersMedianValue}
                  chartData={barCharts.creditScoreTiersArray}
                  title="Credit Score Tiers"
                  legendData={[
                    'Poor: 300-579',
                    'Fair: 580-669',
                    'Good: 670-739',
                    'Very good: 740-799',
                    'Exceptional: 800-850',
                  ]}
                />
                <RaceChart
                  isComparison={true}
                  isDataExist={true}
                  isLoading={fullWindow || results[0]?.isLoading}
                  cuName={CuData.cuName}
                  comparisonName={locationName}
                  chartData={barCharts.monteCarloRace}
                  comparisonChartData={barCharts.customLocationRace}
                  setMethodology={setSelected}
                />
              </div>
            )}
          </>
        </PageWrapper>
      </>
    );
  }
);

export default ImpactReportPage;
