import React, { FC } from 'react';
import s from './Export.module.scss';

type ExportHeaderType = {
  image: string;
  cuText: string;
  impactIn: string;
};

const ExportHeader: FC<ExportHeaderType> = ({ image, cuText, impactIn }) => (
  <div className={s.header}>
    <div className={s.header__left}>
      <div className={s.header__left__page}>Impact Report</div>
      <div className={s.header__left__title}>
        <span style={{ color: 'rgba(35, 43, 149, 1)' }}>
          {`${cuText}${impactIn === 'All Geographies' ? 'ʼs' : ''}`}
        </span>{' '}
        Impact
        {impactIn === 'All Geographies' ? '' : 'in'}{' '}
        <span style={{ color: 'rgba(82, 179, 107, 1)' }}>
          {impactIn === 'All Geographies' ? '' : impactIn}
        </span>
      </div>
    </div>
    <div className={s.header__right}>
      <img
        src={image}
        alt="cu_logo"
        style={{ width: '100%', maxWidth: '60px', height: 'auto' }}
      />
    </div>
  </div>
);

export default ExportHeader;
